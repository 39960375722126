import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Campaign } from "../../types/campaign";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { Link as ReactLink } from "react-router-dom";
import TargetIcon from "../../assets/icons/target.png";
import { PlusSquareIcon } from "@chakra-ui/icons";

export const ProjectCampaignsList = (props: { projectId: string }) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>();

  const getCampaigns = async () => {
    const q = query(
      collection(firestore, "campaigns"),
      where("projectId", "==", props.projectId)
    );
    const snap = await getDocs(q);
    setCampaigns(snap.docs.map((d) => d.data() as Campaign));
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <VStack w={"100%"} alignItems={"flex-start"} spacing={5}>
      <Heading color={"white"}> Project Quests </Heading>
      <HStack w={"100%"} spacing={5} wrap={"wrap"} alignItems={"stretch"}>
        {campaigns?.map((c) => {
          return (
            <Card
              bg={"gray.700"}
              bgImage={`url(${c.image})`}
              bgSize={"cover"}
              bgPosition={"center"}
              maxW={"22.5em"}
              h={"10em"}
            >
              <CardBody>
                <VStack h={"100%"} justifyContent={"space-between"}>
                  <HStack w={"100%"} justifyContent={"flex-start"}>
                    <Box borderRadius={10} padding={2} bg={"gray.700"}>
                      <Heading size={"sm"} color={"white"}>
                        {c.title} on {c.platform}
                      </Heading>
                    </Box>
                  </HStack>

                  <HStack
                    wrap={"wrap"}
                    w={"100%"}
                    justifyContent={"space-evenly"}
                  >
                    <HStack
                      wrap={"wrap"}
                      borderRadius={10}
                      padding={2}
                      bg="gray.700"
                    >
                      <Image src={TargetIcon} w={8} h={8} />
                      <Heading size={"sm"} color={"white"}>
                        Hit: {c.range} Peepers
                      </Heading>
                    </HStack>
                    {c.status == "In review" && (
                      <Box borderRadius={10} padding={2} bg={"yellow"}>
                        <Heading size={"sm"}>Status: {c.status}</Heading>
                      </Box>
                    )}
                  </HStack>
                </VStack>
              </CardBody>
            </Card>
          );
        })}

        <Card
          bg={"gray.700"}
          opacity={0.8}
          border={"1px solid white"}
          w={"15em"}
        >
          <CardBody>
            <Link as={ReactLink} to={`/newcampaign/${props.projectId}`}>
              <VStack
                justifyContent={"center"}
                h={"100%"}
                alignItems={"center"}
              >
                <Icon as={PlusSquareIcon} color={"white"} w={10} h={10} />
                <Heading color={"white"} size={"md"}>
                  New Quest
                </Heading>
              </VStack>
            </Link>
          </CardBody>
        </Card>
      </HStack>
    </VStack>
  );
};
