import { Container, Heading, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Project } from "../types/project"
import { doc, onSnapshot } from "firebase/firestore"
import { firestore, auth } from "../firebase"
import { LoadingPage } from "./Loading"
import { NewCampaing } from "../components/new-campaing"
import { LoggedNavBar } from "../components/navbars"

export const NewCampaignPage = () => {
    const {projectId} = useParams() as {projectId: string}
    const [project, setProject] = useState<Project>()
    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        const ref = doc(firestore, 'projects', projectId)
        const unsub = onSnapshot(ref, (snap) => {
            if(!snap.exists()) {
                toast({
                    title: 'Project not found',
                    isClosable: true,
                    duration: 3000,
                    status: 'error'
                })
                return navigate('/myprojects')
            }
            const data = snap.data() as Project
            if(data.creatorId !== auth.currentUser?.uid) {
                toast({
                    title: 'Project not found',
                    isClosable: true,
                    duration: 3000,
                    status: 'error'
                })
                return navigate('/myprojects')
            }
            setProject(data)
        })
        
        return () => unsub()
    }, [])

    if(!project) {
        return <LoadingPage />
    }

    return (
        <>
        <LoggedNavBar />
    <Container minW={'90%'} minH={'100vh'}>
    <Heading color={'white'} marginY={5}>Create Campaing for {project.name}</Heading>
    <NewCampaing projectId={projectId} project={project}/>
    </Container>
    </> 
)
}