import {
  Flex,
  Avatar,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import { auth } from "../../firebase";

export const UserSectionHeader = () => {

  const name = auth.currentUser?.displayName ?? "";
  const photo = auth.currentUser?.photoURL ?? "";

  return (
    <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
    <Avatar name={name} size={'lg'} src={photo} />
    <Box>
      <Heading size="lg" color={"white"}>
        Welcome {name}
      </Heading>
      <Text color={"white"}>let`s make some money</Text>
    </Box>
  </Flex>
  );
};
