import {
  Card,
  CardBody,
  Image,
  VStack,
  HStack,
  Text,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PeeperLogo from "../../assets/icons/peeper.png";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebase";
import { DEFAULT_CURRENCY } from "../../config/constants";

export const AvailableToClaimCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0)

  const loadAvailableToClaim = async () => {
    setIsLoading(true)
    const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'AvailableToClaim', DEFAULT_CURRENCY.id)
    const snap = await getDoc(ref)
    if(snap.exists()) {
        setAmount(snap.data().amount)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadAvailableToClaim()
  }, [])

  return (
    <Card bg={"gray.700"} w={"22.5em"}>
      <CardBody>
        <VStack
          w={"100%"}
          alignItems={"flex-start"}
          justifyContent={"space-around"}
          h={"100%"}
        >
          <HStack alignItems={"center"}>
            <Image src={PeeperLogo} color={"gold"} w={16} h={16} />
            <VStack spacing={"0"}>
              <HStack
                w={"100%"}
                justifyContent={"flex-start"}
                alignItems={"baseline"}
              >
                {isLoading ? (
                  <Spinner color="white" size={"lg"} />
                ) : (
                  <Text
                    fontFamily={"gotham-rounded-bold"}
                    color={"whitesmoke"}
                    fontSize={"x-large"}
                  >
                    {amount}
                  </Text>
                )}
                <Text color={"whitesmoke"} fontSize={"medium"}>
                  PEEPER
                </Text>
              </HStack>

              <Text color={"whitesmoke"} fontSize={"medium"}>
                Available to claim
              </Text>
            </VStack>
          </HStack>
          <HStack justifyContent={"center"} w={"100%"}>
            <Button isDisabled={amount <= 0} w={"100%"} colorScheme="pink" as={Link} to="/myearnings">
              Claim
            </Button>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};
