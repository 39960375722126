import { Button, HStack, Heading, Image, Modal, ModalBody, Text, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, useDisclosure } from "@chakra-ui/react"
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from "wagmi"
import WalletIcon from '../../assets/icons/wallet.svg'
import { useEffect, useState } from "react";
import { RepeatIcon } from "@chakra-ui/icons";
import MetamaskIcon from '../../assets/icons/metamask.svg'
import CoinbaseIcon from '../../assets/icons/coinbase.svg'
import WalletConnect from '../../assets/icons/walletconnect.svg'

export const useIsMounted = () => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => setMounted(true), []);
    return mounted;
  };

  const parseConnectorLogo = (name: string) => {
    switch (name) {
      case 'MetaMask':
        return <Image src={MetamaskIcon} w={10} h={10} marginInline={3}/>
    
      case 'Coinbase Wallet':
        return <Image src={CoinbaseIcon} w={16} h={16}/>
  
      case 'WalletConnect':
        return <Image src={WalletConnect} w={16} h={16}/>
  
      default:
        return <></>
    }
  }

export const ConnectedWallet = ({ networkId, children }: { networkId?: number, children: JSX.Element }) => {
    const {address} = useAccount()
    const {chain} = useNetwork()
    const {switchNetwork} = useSwitchNetwork()
    const [rightNetwork, setRightNetwork] = useState(false)
    const isMounted = useIsMounted();
    const {connectors, connect, pendingConnector, isLoading} = useConnect()
    const {isOpen, onOpen, onClose} = useDisclosure()

    useEffect(() => {
      if(networkId) {
        if(chain?.id !== networkId) {
          setRightNetwork(false)
        } else {
          setRightNetwork(true)
        }
      }
    }, [networkId, chain])

    if(!address) return (
    <>
    <Modal  isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={'gray.700'} color={'white'}>
            <ModalHeader>Select a provider to connect your wallet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <VStack spacing={2}>
                {connectors.map((connector) => {
              return <Button
              padding={2}
              _hover={{border: '1px solid white', borderRadius: 10}}
                variant={'unstyled'}
                color={'white'}
                w={'100%'}
                h={20}
                disabled={isMounted ? !connector.ready : true}
                key={connector.id}
                onClick={() => connect({ connector })}
              >
                <HStack w={'100%'}>
                  {parseConnectorLogo(connector.name)}
                  <VStack maxW={'100%'} justifyContent={'center'} alignItems={'flex-start'}>
                      <Heading size={'md'}>{connector.name}</Heading>
                      <Text flexWrap={'wrap'} wordBreak={'break-word'}>Connect With {connector.name}
                      {isMounted && !connector.ready && " (browser unsupported)"}
                {isLoading &&
                  connector.id === pendingConnector?.id &&
                  " (connecting)"}
                      </Text>
                  </VStack>
                </HStack>

              </Button>
            })}
            </VStack>
            </ModalBody>
        </ModalContent>
    </Modal>
    <Button w={'100%'} onClick={onOpen} rightIcon={<Image src={WalletIcon} w={8} h={8} />} colorScheme="orange">
        Connect wallet
    </Button>
    </>)

    if(networkId && !rightNetwork) {
      return <Button w={'100%'} onClick={() => {
        if(switchNetwork) switchNetwork(networkId)
      }} rightIcon={<RepeatIcon w={8} h={8}/>} colorScheme="orange">
        Switch network
    </Button>
    }

    return children
}