import HeartIcon from '../assets/icons/heart.png'
import RetweetIcon from '../assets/icons/retweet.png'
import MentionIcon from '../assets/icons/mention.png'
import HashtagIcon from '../assets/icons/hashtag.png'
import TGIcon from '../assets/icons/telegram.png'
import XIcon from '../assets/icons/twitter-white.svg'

export const Campaings: any = {
    telegram: {
        icon: TGIcon,
        joingroup: {
            pricePerJoin: 30,
            minimumRange: 100,
            previewMessage: 'Join',
            icon: TGIcon,
            title: 'Paste the group link or invite',
            description: 'Users that enters in your group will be rewarded'
        },
        engage: {
            pricePerJoin: 20,
            minimumRange: 100,
            previewMessage: 'Engage',
            icon: HeartIcon,
            title: 'Select some engagement type',
            description: 'Users that engage in your group will be rewarded'
        },
    },
    twitter: {
        icon: XIcon,
        follow: {
            pricePerJoin: 20,
            minimumRange: 100,
            previewMessage: 'Follow',
            icon: HeartIcon,
            title: 'Users will follow:',
            description: 'Users that follow this profile will be rewarded'
        },
        retweet: {
            pricePerJoin: 10,
            minimumRange: 100,
            title: 'Paste the tweet link',
            icon: RetweetIcon,
            previewMessage: 'Retweet',
            description: 'Users that retweet will be rewarded'
        },
        mention: {
            pricePerJoin: 10,
            minimumRange: 100,
            previewMessage: 'Mention',
            icon: MentionIcon,
            title: 'Type the @ of pages or users that need to be mentioned',
            description: 'Users that make a tweet mention the persons or pages will be rewarded',
        },
        hashtag: {
            pricePerJoin: 10,
            minimumRange: 100,
            previewMessage: 'Hashtag',
            icon: HashtagIcon,
            title: 'Type the # you want to see booming!',
            description: 'Users that make a tweet with this hashtag will be rewarded'
        },
    }
}