import { Button, Container, HStack, Heading, Image, Table, TableCaption, Text, TableContainer, Tbody, Td, Th, Thead, Tr, Modal, useDisclosure, ModalContent, ModalBody, Alert, AlertIcon, ModalOverlay, VStack, ModalHeader, useToast, Link } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Earning } from "../types/earning"
import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import { auth, firestore, service } from "../firebase"
import { LoadingPage } from "./Loading"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import { LoggedNavBar } from "../components/navbars"
import { ConnectedWallet } from "../components/connected-wallet"
import { useAccount } from "wagmi"
import { useClaim } from "../services/PeeperClaimer"

export const MyEarningsPage = () => {
    const [earnings, setEarnings] = useState<Earning[]>()

    useEffect(() => {
        const ref = collection(firestore, 'users', auth.currentUser?.uid ?? "", 'earnings')
        const q = query(ref, orderBy('createdAt', 'desc'))
        const unsub = onSnapshot(q, (snap) => {
            setEarnings(snap.docs.map(d => d.data() as Earning))
        })

        return () => unsub()
    }, [])

    const GeneratePaymentOrderButton = (props: {earning: Earning}) => {
        const {isOpen, onOpen, onClose} = useDisclosure()
        const { address } = useAccount()
        const [isLoading, setIsLoading] = useState(false)
        const toast = useToast()
        const handleConfirm = async () => {
            setIsLoading(true)
            try {
                await service.createClaimSignature({
                    currencyId: props.earning.currencyId, 
                    currencyAddress: props.earning.currencyAddress, 
                    address
                })
                toast({
                    status: 'success',
                    title: 'Withdraw order created!',
                    description: 'You can claim now'
                })
            } catch (error) {
                toast({
                    status: 'error',
                    title: 'Internal server error',
                    description: 'Try again in some minutes'
                })
            }
            setIsLoading(false)
            onClose()
        }

        return <>
        <ConnectedWallet networkId={Number(props.earning.currencyNetwotkId)}>
        <Button onClick={onOpen} w={"100%"} colorScheme="yellow">
        Create claim order
  </Button>
  </ConnectedWallet >
  <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={'gray.700'} color={'white'}>
            <ModalHeader>Create withdraw order</ModalHeader>
            <ModalBody>
                <VStack>
                <Alert color={'black'} borderRadius={10} status="warning">
                    <AlertIcon />
                    You are withdrawing all your earnings from {props.earning.currencySymbol} to the address: {`${address?.slice(0, 5)}...${address?.slice(address.length - 5)}`}
                </Alert>
                <Text>
                Once your withdrawal order has been created, you need to carry out a transaction from this wallet to the claim contract
                </Text>
                <Button onClick={handleConfirm} isLoading={isLoading} w={'100%'} colorScheme="pink">
                Create my withdrawal
                </Button>
                </VStack>
            </ModalBody>
        </ModalContent>
  </Modal>
  </>
    }

    const ClaimButton = (props: {earning: Earning}) => {
        const {isLoading, write, data} = useClaim({
            amount: props.earning.paymentOrderAmount ?? "",
            nonce: props.earning.paymentOrderNonce ?? 0,
            signature: props.earning.signature ?? "",
            tokenTarget: props.earning.currencyAddress ?? ""
        })
        const toast = useToast()
        useEffect(() => {
            if(data?.hash) {
                service.updateClaimTx({
                    signature: props.earning.signature, 
                    txhash: data.hash
                })
                toast({
                    status: 'success',
                    title: 'Transaction sent to the blockchain',
                    description: 'txhash: '+ data.hash,
                    duration: 9000,
                    isClosable: true
                })
            }
        }, [data])

        const handle = async () => {
            if(write) write()
        }

        return <ConnectedWallet networkId={Number(props.earning.currencyNetwotkId)}>
            <Button onClick={handle} isLoading={isLoading} w={"100%"} colorScheme="pink">
            Claim
            </Button>
        </ConnectedWallet>
    }

    const ActionButton = (props: {earning: Earning}) => {
        const {earning} = props

        if(!earning.signature) {
            return <GeneratePaymentOrderButton earning={props.earning} />
        }
    
        if(!earning.txhash) {
            return <ClaimButton earning={props.earning} />
        }
    
        return <Button as={Link} target="_blank" href={`https://bscscan.com/tx/${props.earning.txhash}`} w={"100%"} colorScheme="green">
            Check tx
      </Button>
      }

    if(!earnings) return <LoadingPage />

    return (
        <>
        <LoggedNavBar />
        <Container minW={'90%'} minH={'100vh'}>
        <Heading color={'white'} marginY={5}>My Earnings</Heading>
        <TableContainer>
        <Table bg={'gray.700'} color={'white'} borderRadius={10} padding={5}>
            <TableCaption>My earnings</TableCaption>
            <Thead >
                <Tr>
                <Th color={'white'}>Type</Th>
                <Th color={'white'}>Quest</Th>
                <Th color={'white'}>Currency</Th>
                <Th color={'white'}>Amount</Th>
                <Th color={'white'}>Action</Th>
                </Tr>
            </Thead>
            <Tbody>
                {earnings.map(d => (
                    <Tr key={d.createdAt.toString()}>
                        <Td>{d.type}</Td>
                        <Td>{d.campaignId ? 
                        <Button as={Link} href={`/quest/${d.campaignId}`} colorScheme={'blue'} rightIcon={<ExternalLinkIcon />}>
                            Quest
                        </Button>: <></>}</Td>
                        <Td>
                            <HStack>
                            <Image src={d.currencyImage} w={6} h={6} />
                            <Text>
                            {d.currencySymbol}
                            </Text>
                            </HStack>
                            </Td>
                        <Td>${d.amount}</Td>
                        <Td>
                            <ActionButton earning={d}/>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
        </TableContainer>
    </Container>
    </>
    )
}