import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Campaign } from "../types/campaign";
import { Container, HStack, Heading, VStack, useToast, Image, Box, Text, Link, Progress, Card, CardBody, CardHeader, IconButton } from "@chakra-ui/react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import { LoadingPage } from "./Loading";
import { Campaings } from "../config/campaings";
import { QuestRenderInputs } from "../components/quests-render-inputs";
import { LoggedNavBar } from "../components/navbars";
import { Project } from "../types/project";
import RewardIcon from "../assets/icons/peeper.png";
import { LinkIcon } from "@chakra-ui/icons";
import TGIcon from '../assets/icons/telegram.png'
import XIcon from '../assets/icons/twitter.svg'
import DiscordIcon from '../assets/icons/discord.svg'
import InstagramIcon from '../assets/icons/instagram.svg'

const RenderQuest = (props: {quest: Campaign}) => {
  const {quest} = props
  const [project, setProject] = useState<Project>()

  useEffect(() => {
    const projectRef = doc(firestore, 'projects', quest.projectId)
    getDoc(projectRef).then((snap) => {
      setProject(snap.data() as Project)
    })
  }, [])

  return <VStack bg={'gray.700'} borderRadius={10} padding={2} spacing={5}>
  <HStack>
        <Heading color={"white"}>
              {quest.title} on{" "}
            </Heading>
            <Image src={Campaings[quest.platform].icon} w={8} h={8} />
            </HStack>
            <Box
              padding={2}
                borderRadius={10}
                bg={"gray.700"}
                bgImage={`url(${quest.image})`}
                bgSize={"cover"}
                bgPosition={"center"}
                w={'100%'}
                maxW={'400px'}
                h={'25em'}
                color={'white'}
            >
              <VStack h={'100%'} justifyContent={'space-between'}>
                <HStack justifyContent={'flex-start'} w={'100%'}>
                  <HStack borderRadius={10}
                    bg={"gray.900"} padding={2}>
                    <Heading size={'sm'}>Powered by {project?.name}</Heading>
                    <Image src={project?.image} w={8} h={8} borderRadius={'full'} />
                  </HStack>
                </HStack>

                <HStack justifyContent={'space-between'} w={'100%'}>
                  <Box bg={'teal'} padding={2} borderRadius={10}>
                    <Heading size={'md'} fontFamily={'gotham-rounded-bold'}>Active</Heading>
                  </Box>
                  <HStack borderRadius={10}
                    bg={"gray.900"} padding={2}>
                <Heading size={"sm"} color={"white"}>
                  Prize $
                  {Campaings[quest.platform][quest.type].pricePerJoin}{" "}
                </Heading>
                <Image src={RewardIcon} w={8} h={8} />
                  </HStack>
                </HStack>
              </VStack>
            </Box>
            <Progress isAnimated minW={'20em'} maxW={'25em'} borderRadius={10} colorScheme="blue" hasStripe value={Number(((quest.participantsCount/quest.range) * 100).toFixed(0))}/>
            <QuestRenderInputs platform={quest.platform} type={quest.type} campaign={quest}/>
            <Heading color={'white'} fontFamily={"gotham-rounded-bold"} size={'lg'}>About Project</Heading>
            {project && 
                        <HStack marginY={5} wrap={'wrap'} spacing={0} w={'100%'} justifyContent={'center'} alignItems={'stretch'}>
                        <Box padding={2} bgImage={`url(${project?.image})`} minH={'10em'} minW={'20em'} borderRadius={10} bgSize={'cover'} bgPosition={'center'}>
                        </Box>
                        <Card minW={'20em'} bg={"gray.700"}>
                            <CardHeader>
                                <Heading color={'white'}>{project?.name}</Heading>
                            </CardHeader>
                            <CardBody>
                              <VStack spacing={4}>
                                <Heading color={'white'} size={'md'}>{project?.description}</Heading>
                                {project?.website && <Link color={'white'} fontSize={'x-large'} href={project.website} isExternal>
                                    <LinkIcon mx='2px' w={8} h={8}/> {project?.website}
                                </Link>}
                                <HStack wrap={'wrap'} spacing={2} justifyContent={'space-around'} w={'100%'}>
                                {project?.telegram && 
                                          <a target="_blank" href={project.telegram}>
                                          <IconButton aria-label='twitter'
                                   variant={'solid'} colorScheme="blue" icon={<Image src={TGIcon} w={12} h={12} />} size={'lg'}>
                                              </IconButton>
                                              </a>
                                }
                
                                {project?.twitter && <a target="_blank" href={project.twitter}>
                                          <IconButton aria-label='twitter'
                                   variant={'solid'} colorScheme="gray" icon={<Image src={XIcon} w={12} h={12} />} size={'lg'}>
                                              </IconButton>
                                              </a>
                                              }
                
                                {project?.discord && <a target="_blank" href={project.discord}>
                                          <IconButton aria-label='twitter'
                                   variant={'solid'} icon={<Image src={DiscordIcon} w={12} h={12} />} size={'lg'}>
                                              </IconButton>
                                              </a>}
                
                                {project?.instagram && <a target="_blank" href={project.instagram}>
                                          <IconButton aria-label='twitter'
                                   variant={'solid'} icon={<Image src={InstagramIcon} w={12} h={12} />} size={'lg'}>
                                              </IconButton>
                                              </a>
                                          }
                                </HStack>
                                </VStack>
                            </CardBody>
                        </Card>
                    </HStack>
            }

            
            </VStack>
}

export const QuestPage = () => {
  const { questId } = useParams() as { questId: string };
  const [quest, setQuest] = useState<Campaign>();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const ref = doc(firestore, "campaigns", questId);
    const unsub = onSnapshot(ref, (snap) => {
      if (!snap.exists()) {
        toast({
          title: "Quest not found",
          isClosable: true,
          duration: 3000,
          status: "error",
        });
        return navigate("/");
      }
      const data = snap.data() as Campaign;
      setQuest(data);
    });
    return () => unsub();
  }, []);

  if (!quest) {
    return <LoadingPage />;
  }

  return (
    <>
    <LoggedNavBar />
    <Container minW={"90%"} paddingBottom={5} minH={"100vh"}>
    <RenderQuest quest={quest}/>
    </Container>
    </>
  );
};
