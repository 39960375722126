import {
  Card,
  CardBody,
  VStack,
  HStack,
  Heading,
  Image,
  Link,
} from "@chakra-ui/react";
import { Campaign } from "../../types/campaign";
import { Campaings } from "../../config/campaings";
import RewardIcon from "../../assets/icons/peeper.png";
import { Link as ReactRouterLink } from 'react-router-dom'

export const JoinToCampaing = (props: { campaign: Campaign }) => {
  const { campaign } = props;

  return (
    <Link as={ReactRouterLink} to={`/quest/${props.campaign.paymentTransactionHash}`}>
      <Card boxShadow={'2xl'} w={"22.5em"} h={"14em"} borderRadius={10}>
        <CardBody
        borderRadius={10}
          bg={"gray.700"}
          bgImage={`url(${campaign.image})`}
          bgSize={"cover"}
          bgPosition={"center"}
          _hover={{
            opacity: 0.8,
          }}
          onClick={() => {}}
        >
          <VStack h={"100%"} justifyContent={"space-between"}>
            <HStack w={"100%"} justifyContent={"flex-start"}>
              <HStack
                wrap={"wrap"}
                borderRadius={10}
                padding={2}
                bg={"gray.700"}
              >
                <Heading size={"sm"} color={"white"}>
                  {campaign.title} on{" "}
                </Heading>
                <Image src={Campaings[campaign.platform].icon} w={8} h={8} />
              </HStack>
            </HStack>
            <HStack w={"100%"} justifyContent={"space-between"}>
              <HStack borderRadius={10} padding={2} bg={"gray.700"}>
                <Image
                  src={Campaings[campaign.platform][campaign.type].icon}
                  w={8}
                  h={8}
                />
                <Heading size={"sm"} color={"white"}>
                  {Campaings[campaign.platform][campaign.type].previewMessage}{" "}
                </Heading>
              </HStack>
              <HStack borderRadius={10} padding={2} bg={"gray.700"}>
                <Heading size={"sm"} color={"white"}>
                  Prize $
                  {Campaings[campaign.platform][campaign.type].pricePerJoin}{" "}
                </Heading>
                <Image src={RewardIcon} w={8} h={8} />
              </HStack>
            </HStack>
          </VStack>
        </CardBody>
      </Card>
    </Link>
  );
};
