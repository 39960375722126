import { WagmiConfig } from 'wagmi';
import { config } from './config/wagmi';
import { Alert, Text, AlertDescription, AlertIcon, AlertTitle, Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { AppEntry } from './Router';


function App() {
  return (
    <WagmiConfig config={config}>
      <ChakraProvider>
        <Alert flexWrap={'wrap'} status="warning">
                <AlertIcon />
                <AlertTitle>Peeper ALPHA - Version 0.8</AlertTitle>
                <AlertDescription>This is a beta version of Peeper. if you face some issue, please, report to us on telegram</AlertDescription>
            </Alert>
            <AppEntry />
            <Box as="footer" bg="gray.900" color="white" py="4">
      <Flex justify="center">
        <Text>&copy; Peeper Mint. All rights reserved.</Text>
      </Flex>
    </Box>
      </ChakraProvider>
    </WagmiConfig>
  );
}

export default App;
