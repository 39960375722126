import { HStack, Heading, Link, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Project } from "../../types/project"
import { NewProject } from "../new-project"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { auth, firestore } from "../../firebase"
import { Link as ReactRouterLink } from 'react-router-dom'

export const MyProjectsList = () => {
    const [projects, setProjects] = useState<{project: Project, projectId: string}[]>([])

    useEffect(() => {
        const ref = collection(firestore, 'projects')
        const q = query(ref, where('creatorId', '==', auth.currentUser?.uid))
        const unsub = onSnapshot(q, (docs) => {
            if(docs.empty) return
            setProjects(docs.docs.map(d => {
                return {
                    project: d.data() as Project,
                    projectId: d.id
                }
            }))
        })

        return () => unsub()
    }, [])

    const ProjectCard = (params: {project: Project, projectId: string}) => {
        return <Link as={ReactRouterLink} to={`/myprojects/${params.projectId}`} padding={2} bgImage={`url(${params.project.image})`} minH={'15em'} w={"15em"} borderRadius={10} bgSize={'cover'} bgPosition={'center'}>
            <VStack h={'100%'} justifyContent={'space-between'}>
                <HStack w={'100%'} justifyContent={'flex-end'}>
                    <NewProject project={params.project} projectId={params.projectId}/>
                </HStack>
                <HStack w={'100%'} justifyContent={'flex-start'}>
                <Heading color={'white'} size={'md'} bg={'black'} borderRadius={'10'} padding={2}>{params.project.name}</Heading>
                </HStack>
            </VStack>
        </Link>
    }

    return (
        <HStack wrap={'wrap'} justifyContent={'flex-start'} alignItems={'stretch'}>
            {projects.map(project => ProjectCard(project))}
            <NewProject />
        </HStack>
    )
}