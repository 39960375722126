import {
    Card,
    CardBody,
    Flex,
    Heading,
    Text,
    useToast,
    HStack,
    Badge,
    Button,
    Spinner,
    VStack,
    Image,
    IconButton,
    Box,
  } from "@chakra-ui/react";
  import { auth, firestore, service } from "../../firebase";
  import { useEffect, useState } from "react";
  import { doc, getDoc } from "firebase/firestore";
  import { DbUser } from "../../types/user";
  import { CopyIcon } from "@chakra-ui/icons";
  import XIcon from '../../assets/icons/twitter.svg'
  import TGIcon from '../../assets/icons/telegram.png'
  import WppIcon from '../../assets/icons/whatsapp.svg'
  
  export const InviteCodeCard = () => {
    const [inviteCode, setInviteCode] = useState<string>();
    const toast = useToast();
  
    const getOrCreateInvite = async () => {
      const userUid = auth.currentUser?.uid ?? "";
      const userRef = doc(firestore, "users", userUid);
      const snap = await getDoc(userRef);
      const data = snap.data() as DbUser;
      if (!data.inviteCode) {
        const resp = await service.createUserInvite();
        setInviteCode(resp.data as any);
        return;
      }
      setInviteCode(data.inviteCode);
    };
  
    useEffect(() => {
        getOrCreateInvite()
    }, [])
  
    return (
      <Card bg={"gray.700"} w={"22.5em"}>
        <CardBody>
          <Flex
            direction={"column"}
            justifyContent={"center"}
            borderRadius={10}
          >
            <Heading textAlign={'left'} w={'100%'} size={'md'} color={'white'}>
              Your invite code:
            </Heading>
            {!inviteCode ? (
              <HStack justifyContent={"center"}>
                {" "}
                <Spinner size="lg" color="white" />{" "}
              </HStack>
            ) : (
              <VStack>
              <HStack marginY={2} justifyContent={"center"}>
                <Box
                  fontFamily={"gotham-rounded-bold"}
                  bg={"teal"}
                  color={'whitesmoke'}
                  padding={1}
                  borderRadius={5}
                  fontSize={"larger"}
                  fontWeight={"900"}
                >
                  {inviteCode}
                </Box>
                <Button
                  borderRadius={20}
                  colorScheme="teal"
                  onClick={() => {
                    navigator.clipboard.writeText(inviteCode);
                    toast({
                      status: "info",
                      title: "invite copied to clipboard",
                      duration: 3000,
                    });
                  }}
                  rightIcon={<CopyIcon />}
                  size={"sm"}
                >
                  Copy
                </Button>
              </HStack>
              <Text color={"whitesmoke"} fontSize={"medium"}>
              You EARN 10 $PEEPER for each one who enters using your invite 
            </Text>
              </VStack>
            )}
          </Flex>
          {
              inviteCode &&
              <VStack>
                  <Heading textAlign={'left'} w={'100%'} size={'md'} color={'white'}>Share on</Heading>
              <HStack wrap={'wrap'} justifyContent={'space-between'}>
          <a target="_blank" href={`https://twitter.com/intent/tweet?text=%F0%9F%9A%80%20Join%20to%20Peeper%20Mint%20%28app.peepermint.com%29%0A%F0%9F%8E%89%20The%20First%20SocialFi%20LaunchPad%0A%F0%9F%92%8C%20Use%20my%20invite%20code%3A%20${inviteCode}%0A%F0%9F%8F%86%20Choose%20your%20Quest%20and%20Monetize%20your%20micro-inluence`}>
          <IconButton aria-label='twitter'
   variant={'solid'} colorScheme="gray" icon={<Image src={XIcon} w={8} h={8} />} size={'lg'}>
              </IconButton>
              </a>
  
              <a target="_blank" href={`https://web.whatsapp.com/send?text=%F0%9F%9A%80%20Join%20to%20Peeper%20Mint%20%28app.peepermint.com%29%0A%F0%9F%8E%89%20The%20First%20SocialFi%20LaunchPad%0A%F0%9F%92%8C%20Use%20my%20invite%20code%3A%20${inviteCode}%0A%F0%9F%8F%86%20Choose%20your%20Quest%20and%20Monetize%20your%20micro-inluence`}>
          <IconButton     aria-label='Whatsapp'      
  variant={'solid'} colorScheme="green" icon={<Image src={WppIcon} w={8} h={8}/>} size={'lg'}>
              </IconButton>
              </a>
  
              <a target="_blank" href={`https://t.me/share/url?url=peeble.xyz&text=%F0%9F%9A%80%20Join%20to%20Peeper%20Mint%20%28app.peepermint.com%29%0A%F0%9F%8E%89%20The%20First%20SocialFi%20LaunchPad%0A%F0%9F%92%8C%20Use%20my%20invite%20code%3A%20${inviteCode}%0A%F0%9F%8F%86%20Choose%20your%20Quest%20and%20Monetize%20your%20micro-inluence`}>
          <IconButton       aria-label='Telegram'    
  variant={'solid'} colorScheme="blue" icon={<Image src={TGIcon} w={8} h={8}/>} size={'lg'}>
              </IconButton>
              </a>
              </HStack>
              </VStack>
          }
        </CardBody>
      </Card>
    );
  };
  