import { Button, Card, CardBody, HStack, Image, Spinner, Text, VStack } from "@chakra-ui/react"
import Logo from '../../assets/icons/wallet.svg'
import ChartUpIcon from '../../assets/icons/chartUp.svg'
import ChartDownIcon from '../../assets/icons/chartDown.svg'
import { usePeeperBalance } from "../../services/PeeperToken"
import { useAccount } from "wagmi"
import { useEffect } from "react"
import { WeiToEth } from "../../utils"
import { ConnectedWallet } from "../connected-wallet"

const Balance = (props: {address: string}) => {
    const {data, isLoading, refetch} = usePeeperBalance({account: props.address})
    
    useEffect(() => {
        const intervalId = setInterval(() => refetch(), 10000)
        return () => clearInterval(intervalId)
    }, [])

    return <HStack w={'100%'} justifyContent={'flex-start'} alignItems={'baseline'}>
    {isLoading ? <Spinner color="white" size={'lg'}/> : 
            <Text fontFamily={'gotham-rounded-bold'} color={'whitesmoke'} fontSize={'x-large'}>{WeiToEth(data as string ?? 0).toString()}</Text>
    }
<Text color={'whitesmoke'} fontSize={'medium'}>PEEPER</Text>
</HStack>
}

export const Wallet = () => {
    const {address} = useAccount()

    return <Card bg={'gray.700'} w={'22.5em'}>
        <CardBody>
            <VStack w={'100%'} alignItems={'flex-start'} justifyContent={'space-around'} h={'100%'}>
            <HStack>
                <Image src={Logo} />
                <VStack spacing={'0'}>
                    <ConnectedWallet>
                        <Balance address={address ?? ""}/>
                    </ConnectedWallet>
                    <Text color={'whitesmoke'} fontSize={'medium'}>Current Balance</Text>
                </VStack>
            </HStack>
            <HStack justifyContent={'center'} w={'100%'}>
                <Button isDisabled colorScheme="green" rightIcon={<Image src={ChartUpIcon}  w={6} h={6} />}>Buy $PEEPER</Button>
                <Button isDisabled variant={'outline'} rightIcon={<Image src={ChartDownIcon}  w={6} h={6} color={'red'}/>} colorScheme="red">SELL $PEEPER</Button>
            </HStack>
            </VStack>
        </CardBody>
    </Card>
}