import { VStack, Text, Button, useSteps, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber, Box, StepDescription, StepSeparator, StepTitle, Image, ScaleFade, HStack, IconButton, FormControl, FormLabel, Input, FormHelperText, NumberInput, NumberInputField, NumberDecrementStepper, NumberIncrementStepper, NumberInputStepper, Heading, Link } from "@chakra-ui/react"
import { useState } from "react";
import TGIcon from '../../assets/icons/telegram.png'
import XIcon from '../../assets/icons/twitter.svg'
import { Project } from "../../types/project";
import { ArrowBackIcon, ArrowRightIcon } from "@chakra-ui/icons";
import HeartIcon from '../../assets/icons/heart.png'
import RetweetIcon from '../../assets/icons/retweet.png'
import MentionIcon from '../../assets/icons/mention.png'
import HashtagIcon from '../../assets/icons/hashtag.png'
import { Campaings } from "../../config/campaings";
import { ConfirmCampaing } from "./confirm-campaing";
import { FillRequirements } from "./fill-requirements";

const SelectPlatform = (props: {setPlatform: React.Dispatch<React.SetStateAction<"twitter" | "telegram" | undefined>>, setActiveStep: React.Dispatch<React.SetStateAction<number>>, project: Project}) => {

    return <ScaleFade initialScale={0.8} in={true}>
        <VStack w={'100%'} spacing={10} justifyContent={'center'} minH={'10em'} width={'20em'}>
            <Button onClick={() => {
                props.setPlatform('telegram');
                props.setActiveStep(1);
            }} isDisabled={!props.project.telegram} colorScheme="blue" w={'100%'} size={'lg'} leftIcon={<Image src={TGIcon} w={8} h={8} />}>
                Telegram
            </Button>
            <Button 
            onClick={() => {
                props.setPlatform('twitter');
                props.setActiveStep(1);
            }} 
            isDisabled={!props.project.twitter} colorScheme="gray" w={'100%'} size={'lg'} leftIcon={<Image src={XIcon} w={8} h={8} />}>
                Twitter
            </Button>
        </VStack>
    </ScaleFade>
}

const SelectTypes = (props: {platform: "twitter" | "telegram", setType: React.Dispatch<React.SetStateAction<string | undefined>>, setActiveStep: React.Dispatch<React.SetStateAction<number>>, project: Project}) => {
    
    const setType = (type: string) => () => {
        props.setType(type);
        props.setActiveStep(2);
    }

    const twitterTypes = (
        <ScaleFade initialScale={0.8} in={true}>
        <VStack w={'100%'} spacing={10} justifyContent={'center'} minH={'10em'} width={'20em'}>
            <HStack w={'100%'} justifyContent={'flex-start'}>
                <IconButton variant={'outline'} size="sm" color={'white'} onClick={() => props.setActiveStep(0)} icon={<ArrowBackIcon />} aria-label="back"/>
            </HStack>
            <Button onClick={setType('follow')} variant={'outline'} color="white" w={'100%'} size={'lg'} leftIcon={<Image src={HeartIcon} w={8} h={8} />}>
                Follow
            </Button>
            <Button onClick={setType('retweet')} variant={'outline'} color="white" w={'100%'} size={'lg'} leftIcon={<Image src={RetweetIcon} w={8} h={8} />}>
                Retweet
            </Button>
            <Button onClick={setType('mention')} variant={'outline'} color="white" w={'100%'} size={'lg'} leftIcon={<Image src={MentionIcon} w={8} h={8} />}>
                Mention
            </Button>
            <Button onClick={setType('hashtag')} variant={'outline'} color="white" w={'100%'} size={'lg'} leftIcon={<Image src={HashtagIcon} w={8} h={8} />}>
                hashtag
            </Button>
        </VStack>
    </ScaleFade>
    )

    const telegramTypes = (
        <ScaleFade initialScale={0.8} in={true}>
        <VStack w={'100%'} spacing={10} justifyContent={'center'} minH={'10em'} width={'20em'}>
            <HStack w={'100%'} justifyContent={'flex-start'}>
                <IconButton variant={'outline'} size="sm" color={'white'} onClick={() => props.setActiveStep(0)} icon={<ArrowBackIcon />} aria-label="back"/>
            </HStack>
            <Button onClick={setType('joingroup')} colorScheme="blue" w={'100%'} size={'lg'} leftIcon={<Image src={TGIcon} w={8} h={8} />}>
                Join group
            </Button>
            <Button onClick={setType('engage')} colorScheme="pink" w={'100%'} size={'lg'} leftIcon={<Image src={HeartIcon} w={8} h={8} />}>
                Engage
            </Button>
        </VStack>
    </ScaleFade>
    )

    if(props.platform == 'twitter') return twitterTypes
    return telegramTypes
}



export const NewCampaing = (props: {projectId: string, project: Project}) => {
    const steps = [
        {title: 'Select Platform', description: 'Where'},
        {title: 'Select Type', description: 'Who'},
        {title: 'Fill Requirements', description: 'What'},
        {title: 'Publish', description: 'Go!'}

    ]
    const {activeStep, setActiveStep} = useSteps({
        index: 0,
        count: steps.length
    })

    const [platform, setPlatform] = useState<'twitter' | 'telegram'>()
    const [type, setType] = useState<string>()
    const [requirements, setRequirements] = useState<{
        target: string
        range: number,
        telegramChatId?: string,
        title?: string
        backgroundImage?: File
    }>()

    return (
    <VStack padding={5} bg={'gray.700'} borderRadius={10} justifyContent={'center'} alignItems={'center'}>
            <Stepper w={'100%'} flexWrap={'wrap'} color={'white'} index={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus 
                                complete={<StepIcon />} 
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                            </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle style={{color: 'white'}}>{step.title}</StepTitle>
            <StepDescription style={{color: 'white'}}>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
                    </Step>
                ))}
            </Stepper>
            {activeStep == 0 && <SelectPlatform setPlatform={setPlatform} setActiveStep={setActiveStep} project={props.project}/>}
            {activeStep == 1 && platform && <SelectTypes setActiveStep={setActiveStep} platform={platform} project={props.project} setType={setType} />}
            {activeStep == 2 && platform && type && <FillRequirements platform={platform} type={type} setRequirements={setRequirements} setActiveStep={setActiveStep} />}
            {activeStep == 3 && platform && type && requirements && <ConfirmCampaing platform={platform} type={type} requirements={requirements} setActiveStep={setActiveStep} project={props.project} projectId={props.projectId} />}
            </VStack>
    )
}