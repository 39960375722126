import {
  VStack,
  Button,
  Box,
  Image,
  ScaleFade,
  HStack,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  useToast,
  Text,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ArrowBackIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Campaings } from "../../config/campaings";
import { auth, service } from "../../firebase";

const TwitterRequirements = (props: {
  type: string;
  setRequirements: React.Dispatch<
    React.SetStateAction<
      | {
          target: string;
          range: number;
          title?: string;
          backgroundImage?: File;
        }
      | undefined
    >
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [target, setTarget] = useState<string>();
  const [range, setRange] = useState(
    Campaings["twitter"][props.type as any].minimumRange as number
  );
  const [title, setTitle] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const toast = useToast()
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handle = () => {
    if (!target) return;
    if(props.type == 'retweet') {
      const regex = /\/status\/(\d+)/;
      const match = target.match(regex);
      if (match && match[1]) {
        const tweetId = match[1];
        setTarget(tweetId)
      } else {
        toast({
          title: 'Invalid tweet url',
          status: 'error'
        })
        return
      }
    }
    props.setRequirements({
      target,
      range,
      title,
      backgroundImage: selectedImage,
    });
    props.setActiveStep(3);
  };

  return (
    <ScaleFade initialScale={0.8} in={true}>
      <VStack
        w={"100%"}
        spacing={10}
        justifyContent={"center"}
        minH={"10em"}
        width={"20em"}
      >
        <HStack w={"100%"} justifyContent={"flex-start"}>
          <IconButton
            variant={"outline"}
            size="sm"
            color={"white"}
            onClick={() => props.setActiveStep(1)}
            icon={<ArrowBackIcon />}
            aria-label="back"
          />
        </HStack>
        <FormControl color={"white"}>
          <FormLabel>Campaign Title</FormLabel>
          <Input
            value={title}
            onChange={(e) => {
              if (e.target.value.length < 31) setTitle(e.target.value);
            }}
          />
          <FormHelperText color={"white"}>
            Using a title in your campaign attracts more attention from users
            (limit {title?.length ?? 0}/30)
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={"white"}>Background Image</FormLabel>
          {selectedImage ? (
            <Box w={"100%"} minH={"5em"} borderRadius={10} overflow={"hidden"}>
              <Image
                src={
                  typeof selectedImage == "string"
                    ? selectedImage
                    : URL.createObjectURL(selectedImage)
                }
                borderRadius={10}
                alt="preview"
                width="100%"
                height="100%"
                mb="2"
              />
              <HStack w={"100%"} justifyContent={"flex-end"}>
                <Button
                  size={"sm"}
                  variant={"ghost"}
                  colorScheme="red"
                  onClick={() => setSelectedImage(undefined)}
                >
                  {" "}
                  Discard Image{" "}
                </Button>
              </HStack>
            </Box>
          ) : (
            <Box w={"100%"} h={"5em"} bg={"gray.600"} borderRadius={10}>
              <VStack
                w={"100%"}
                h={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Input
                  color={"white"}
                  borderColor={"gray.600"}
                  onChange={handleImageChange}
                  type="file"
                  accept="image/*"
                  placeholder="Select a image"
                />
              </VStack>
            </Box>
          )}
          <FormHelperText color={"white"}>
            Using a specific image for your campaign helps to draw users'
            attention and increase the conversion rate, if you don't have an
            image, your project image will be used.
          </FormHelperText>
        </FormControl>

        <FormControl color={"white"} isRequired>
          <FormLabel>{Campaings["twitter"][props.type as any].title}</FormLabel>
          <Input value={target} onChange={(e) => setTarget(e.target.value)} />
          <FormHelperText color={"white"}>
            {Campaings["twitter"][props.type as any].description}
          </FormHelperText>
        </FormControl>
        <FormControl color={"white"} isRequired>
          <FormLabel>
            How many users do you want to participate in your campaign?
          </FormLabel>
          <NumberInput
            value={range}
            onChange={(e) => setRange(Number(e))}
            clampValueOnBlur={true}
            step={10}
            min={Campaings["twitter"][props.type as any].minimumRange}
            defaultValue={Campaings["twitter"][props.type as any].minimumRange}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper color={"white"} />
              <NumberDecrementStepper color={"white"} />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <HStack w={"100%"} justifyContent={"flex-end"}>
          <Button
            onClick={handle}
            isDisabled={
              range < Campaings["twitter"][props.type as any].minimumRange ||
              !target
            }
            colorScheme="pink"
            rightIcon={<ArrowRightIcon />}
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </ScaleFade>
  );
};

const TwitterFollowRequirements = (props: {
  type: string;
  setRequirements: React.Dispatch<
    React.SetStateAction<
      | {
          target: string;
          range: number;
          title?: string;
          backgroundImage?: File;
          creatorTwitterName?: string
          creatorTwitterUsername?: string
          creatorTwitterProfilePhoto?: string 
        }
      | undefined
    >
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [target, setTarget] = useState("")
  const [username, setUsername] = useState("")
  const [range, setRange] = useState(
    Campaings["twitter"][props.type as any].minimumRange as number
  );
  const [title, setTitle] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handle = () => {
    if (!target || !username) return;
    props.setRequirements({
      target,
      range,
      title,
      backgroundImage: selectedImage,
      creatorTwitterName: auth.currentUser?.displayName ?? undefined,
      creatorTwitterProfilePhoto: auth.currentUser?.photoURL ?? undefined,
      creatorTwitterUsername: username
    });
    props.setActiveStep(3);
  };

  useEffect(() => {
    service.twitter.getMyUserId({}).then(resp => {
      const data = resp.data as {id: string, name: string, username: string}
      if(data) {
        setTarget(data.id)
        setUsername(data.username)
      }
    })
  }, [])

  return (
    <ScaleFade initialScale={0.8} in={true}>
      <VStack
        w={"100%"}
        spacing={10}
        justifyContent={"center"}
        minH={"10em"}
        width={"20em"}
      >
        <HStack w={"100%"} justifyContent={"flex-start"}>
          <IconButton
            variant={"outline"}
            size="sm"
            color={"white"}
            onClick={() => props.setActiveStep(1)}
            icon={<ArrowBackIcon />}
            aria-label="back"
          />
        </HStack>
        <FormControl color={"white"}>
          <FormLabel>Campaign Title</FormLabel>
          <Input
            value={title}
            onChange={(e) => {
              if (e.target.value.length < 31) setTitle(e.target.value);
            }}
          />
          <FormHelperText color={"white"}>
            Using a title in your campaign attracts more attention from users
            (limit {title?.length ?? 0}/30)
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={"white"}>Background Image</FormLabel>
          {selectedImage ? (
            <Box w={"100%"} minH={"5em"} borderRadius={10} overflow={"hidden"}>
              <Image
                src={
                  typeof selectedImage == "string"
                    ? selectedImage
                    : URL.createObjectURL(selectedImage)
                }
                borderRadius={10}
                alt="preview"
                width="100%"
                height="100%"
                mb="2"
              />
              <HStack w={"100%"} justifyContent={"flex-end"}>
                <Button
                  size={"sm"}
                  variant={"ghost"}
                  colorScheme="red"
                  onClick={() => setSelectedImage(undefined)}
                >
                  {" "}
                  Discard Image{" "}
                </Button>
              </HStack>
            </Box>
          ) : (
            <Box w={"100%"} h={"5em"} bg={"gray.600"} borderRadius={10}>
              <VStack
                w={"100%"}
                h={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Input
                  color={"white"}
                  borderColor={"gray.600"}
                  onChange={handleImageChange}
                  type="file"
                  accept="image/*"
                  placeholder="Select a image"
                />
              </VStack>
            </Box>
          )}
          <FormHelperText color={"white"}>
            Using a specific image for your campaign helps to draw users'
            attention and increase the conversion rate, if you don't have an
            image, your project image will be used.
          </FormHelperText>
        </FormControl>

        <FormControl color={"white"} isRequired>
          <FormLabel>{Campaings["twitter"][props.type as any].title}</FormLabel>
            <HStack>
              {auth.currentUser?.photoURL && 
                <Avatar name={auth.currentUser.displayName ?? ""} src={auth.currentUser?.photoURL} size={'md'} />
              }
              <VStack alignItems={'flex-start'}>
                <Heading size={'md'}> {auth.currentUser?.displayName} </Heading>
                <Heading size={'sm'}> @{username} </Heading>
              </VStack>
            </HStack>
          <FormHelperText color={"white"}>
            {Campaings["twitter"][props.type as any].description}
          </FormHelperText>
        </FormControl>
        <FormControl color={"white"} isRequired>
          <FormLabel>
            How many users do you want to participate in your campaign?
          </FormLabel>
          <NumberInput
            value={range}
            onChange={(e) => setRange(Number(e))}
            clampValueOnBlur={true}
            step={10}
            min={Campaings["twitter"][props.type as any].minimumRange}
            defaultValue={Campaings["twitter"][props.type as any].minimumRange}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper color={"white"} />
              <NumberDecrementStepper color={"white"} />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <HStack w={"100%"} justifyContent={"flex-end"}>
          <Button
            onClick={handle}
            isDisabled={
              range < Campaings["twitter"][props.type as any].minimumRange ||
              !target
            }
            colorScheme="pink"
            rightIcon={<ArrowRightIcon />}
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </ScaleFade>
  );
}

const TelegramJoinGroupRequirements = (props: {
    type: string;
    setRequirements: React.Dispatch<
      React.SetStateAction<
        | {
            target: string;
            range: number;
            telegramChatId?: string;
            title?: string;
            backgroundImage?: File;
          }
        | undefined
      >
    >;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  }) => {
    const [target, setTarget] = useState<string>();
    const [telegramChatId, setTelegramChatId] = useState<string>();
    const [range, setRange] = useState(
      Campaings["telegram"][props.type as any].minimumRange as number
    );
    const [title, setTitle] = useState<string>();
    const [selectedImage, setSelectedImage] = useState<File>();
  
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (file) {
        setSelectedImage(file);
      }
    };
  
    const handle = () => {
      if (!target) return;
      props.setRequirements({
        target: target.trim(),
        telegramChatId: telegramChatId?.trim(),
        range,
        title: title?.trim(),
        backgroundImage: selectedImage,
      });
      props.setActiveStep(3);
    };
  
    return (
      <ScaleFade initialScale={0.8} in={true}>
        <VStack
          w={"100%"}
          spacing={10}
          justifyContent={"center"}
          minH={"10em"}
          width={"20em"}
        >
          <HStack w={"100%"} justifyContent={"flex-start"}>
            <IconButton
              variant={"outline"}
              size="sm"
              color={"white"}
              onClick={() => props.setActiveStep(1)}
              icon={<ArrowBackIcon />}
              aria-label="back"
            />
          </HStack>
          <FormControl color={"white"}>
            <FormLabel>Campaign Title</FormLabel>
            <Input
              value={title}
              onChange={(e) => {
                if (e.target.value.length < 31) setTitle(e.target.value);
              }}
            />
            <FormHelperText color={"white"}>
              Using a title in your campaign attracts more attention from users
              (limit {title?.length ?? 0}/30)
            </FormHelperText>
          </FormControl>
  
          <FormControl>
            <FormLabel color={"white"}>Background Image</FormLabel>
            {selectedImage ? (
              <Box w={"100%"} minH={"5em"} borderRadius={10} overflow={"hidden"}>
                <Image
                  src={
                    typeof selectedImage == "string"
                      ? selectedImage
                      : URL.createObjectURL(selectedImage)
                  }
                  borderRadius={10}
                  alt="preview"
                  width="100%"
                  height="100%"
                  mb="2"
                />
                <HStack w={"100%"} justifyContent={"flex-end"}>
                  <Button
                    size={"sm"}
                    variant={"ghost"}
                    colorScheme="red"
                    onClick={() => setSelectedImage(undefined)}
                  >
                    {" "}
                    Discard Image{" "}
                  </Button>
                </HStack>
              </Box>
            ) : (
              <Box w={"100%"} h={"5em"} bg={"gray.600"} borderRadius={10}>
                <VStack
                  w={"100%"}
                  h={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Input
                    color={"white"}
                    borderColor={"gray.600"}
                    onChange={handleImageChange}
                    type="file"
                    accept="image/*"
                    placeholder="Select a image"
                  />
                </VStack>
              </Box>
            )}
            <FormHelperText color={"white"}>
              Using a specific image for your campaign helps to draw users'
              attention and increase the conversion rate, if you don't have an
              image, your project image will be used.
            </FormHelperText>
          </FormControl>
  
          <FormControl color={"white"} isRequired>
            <FormLabel>{Campaings["telegram"][props.type as any].title}</FormLabel>
            <Input value={target} onChange={(e) => setTarget(e.target.value)} />
            <FormHelperText color={"white"}>
              {Campaings["telegram"][props.type as any].description}
            </FormHelperText>
          </FormControl>

          <FormControl color={"white"} isRequired>
            <FormLabel>Your group ID:</FormLabel>
            <Input value={telegramChatId} onChange={(e) => setTelegramChatId(e.target.value)} />
            <FormHelperText color={"white"}>
            To find out your group ID:<br/>
            1) add @peeper_mint_bot to your group<br/>
            2) use the command /get_my_group_id<br/>
            </FormHelperText>
          </FormControl>
          <FormControl color={"white"} isRequired>
            <FormLabel>
              How many users do you want to participate in your campaign?
            </FormLabel>
            <NumberInput
              value={range}
              onChange={(e) => setRange(Number(e))}
              clampValueOnBlur={true}
              step={10}
              min={Campaings["telegram"][props.type as any].minimumRange}
              defaultValue={Campaings["telegram"][props.type as any].minimumRange}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper color={"white"} />
                <NumberDecrementStepper color={"white"} />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <HStack w={"100%"} justifyContent={"flex-end"}>
            <Button
              onClick={handle}
              isDisabled={
                range < Campaings["telegram"][props.type as any].minimumRange ||
                !target ||
                !telegramChatId
              }
              colorScheme="pink"
              rightIcon={<ArrowRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </VStack>
      </ScaleFade>
    );
  };

const TelegramEngageRequirements = (props: {
  type: string;
  setRequirements: React.Dispatch<
    React.SetStateAction<
      | {
          target: string;
          range: number;
          telegramChatId?: string;
          title?: string;
          backgroundImage?: File;
        }
      | undefined
    >
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [target, setTarget] = useState<string>('/My name: (.+)\nAbout me: (.+)/');
  const [telegramChatId, setTelegramChatId] = useState<string>();
  const [range, setRange] = useState(
    Campaings["telegram"][props.type as any].minimumRange as number
  );
  const [title, setTitle] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File>();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handle = () => {
    if (!target) return;
    props.setRequirements({
      target: target.trim(),
      telegramChatId: telegramChatId?.trim(),
      range,
      title: title?.trim(),
      backgroundImage: selectedImage,
    });
    props.setActiveStep(3);
  };

  return <ScaleFade initialScale={0.8} in={true}>
  <VStack
    w={"100%"}
    spacing={10}
    justifyContent={"center"}
    minH={"10em"}
    width={"20em"}
  >
    <HStack w={"100%"} justifyContent={"flex-start"}>
      <IconButton
        variant={"outline"}
        size="sm"
        color={"white"}
        onClick={() => props.setActiveStep(1)}
        icon={<ArrowBackIcon />}
        aria-label="back"
      />
    </HStack>
    <FormControl color={"white"}>
      <FormLabel>Campaign Title</FormLabel>
      <Input
        value={title}
        onChange={(e) => {
          if (e.target.value.length < 31) setTitle(e.target.value);
        }}
      />
      <FormHelperText color={"white"}>
        Using a title in your campaign attracts more attention from users
        (limit {title?.length ?? 0}/30)
      </FormHelperText>
    </FormControl>

    <FormControl>
      <FormLabel color={"white"}>Background Image</FormLabel>
      {selectedImage ? (
        <Box w={"100%"} minH={"5em"} borderRadius={10} overflow={"hidden"}>
          <Image
            src={
              typeof selectedImage == "string"
                ? selectedImage
                : URL.createObjectURL(selectedImage)
            }
            borderRadius={10}
            alt="preview"
            width="100%"
            height="100%"
            mb="2"
          />
          <HStack w={"100%"} justifyContent={"flex-end"}>
            <Button
              size={"sm"}
              variant={"ghost"}
              colorScheme="red"
              onClick={() => setSelectedImage(undefined)}
            >
              {" "}
              Discard Image{" "}
            </Button>
          </HStack>
        </Box>
      ) : (
        <Box w={"100%"} h={"5em"} bg={"gray.600"} borderRadius={10}>
          <VStack
            w={"100%"}
            h={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Input
              color={"white"}
              borderColor={"gray.600"}
              onChange={handleImageChange}
              type="file"
              accept="image/*"
              placeholder="Select a image"
            />
          </VStack>
        </Box>
      )}
      <FormHelperText color={"white"}>
        Using a specific image for your campaign helps to draw users'
        attention and increase the conversion rate, if you don't have an
        image, your project image will be used.
      </FormHelperText>
    </FormControl>

    <FormControl color={"white"} isRequired>
      <FormLabel>{Campaings["telegram"][props.type as any].title}</FormLabel>
      <HStack w={'100%'} wrap={'wrap'} spacing={5}>
        <Card 
        onClick={() => setTarget('/My name: (.+)\nAbout me: (.+)/')}
        border={
          target == '/My name: (.+)\nAbout me: (.+)/'
          ? '1px solid blue'
          : 'none'
        }
        bg={'gray.900'} color={'white'}>
          <CardHeader fontFamily={'gotham-rounded-bold'}>Introducing Yourself</CardHeader>
          <CardBody >
            <VStack spacing={2}>
            <Text fontFamily={'gotham-rounded-bold'}>In this type, the user must send a message introducing themselves to the group, with a predefined message format</Text>
            <Text>Example:</Text>
            <Text w={'100%'} textAlign={'left'}>
            My name: Peeper<br/>
            About me: I am the amazing mascot of Peeper Mint Project
            </Text>
            </VStack>
          </CardBody>
        </Card>

        <Card 
        onClick={() => setTarget('/My prediction: (.+)/')}
        border={
          target == '/My prediction: (.+)/'
          ? '1px solid blue'
          : 'none'
        }
        bg={'gray.900'} color={'white'}>
          <CardHeader fontFamily={'gotham-rounded-bold'}>User prediction</CardHeader>
          <CardBody >
            <VStack spacing={2}>
            <Text fontFamily={'gotham-rounded-bold'}>In this type of campaign, the user has to send a message with their forecast about your project</Text>
            <Text>Example:</Text>
            <Text w={'100%'} textAlign={'left'}>
            My prediction: Peeper will create a new way for SocialFi<br/>
            </Text>
            </VStack>
          </CardBody>
        </Card>
        </HStack>
      <FormHelperText color={"white"}>
        {Campaings["telegram"][props.type as any].description}
      </FormHelperText>
    </FormControl>

    <FormControl color={"white"} isRequired>
      <FormLabel>Your group ID:</FormLabel>
      <Input value={telegramChatId} onChange={(e) => setTelegramChatId(e.target.value)} />
      <FormHelperText color={"white"}>
      To find out your group ID:<br/>
      1) add @peeper_mint_bot to your group<br/>
      2) use the command /get_my_group_id<br/>
      </FormHelperText>
    </FormControl>
    <FormControl color={"white"} isRequired>
      <FormLabel>
        How many users do you want to participate in your campaign?
      </FormLabel>
      <NumberInput
        value={range}
        onChange={(e) => setRange(Number(e))}
        clampValueOnBlur={true}
        step={10}
        min={Campaings["telegram"][props.type as any].minimumRange}
        defaultValue={Campaings["telegram"][props.type as any].minimumRange}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper color={"white"} />
          <NumberDecrementStepper color={"white"} />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
    <HStack w={"100%"} justifyContent={"flex-end"}>
      <Button
        onClick={handle}
        isDisabled={
          range < Campaings["telegram"][props.type as any].minimumRange ||
          !target ||
          !telegramChatId
        }
        colorScheme="pink"
        rightIcon={<ArrowRightIcon />}
      >
        Next
      </Button>
    </HStack>
  </VStack>
</ScaleFade>
}

  const FillRequirementsInputs: {
    [platform: string]: {
      [type: string]: (props: {
        type: string;
        setRequirements: React.Dispatch<React.SetStateAction<{
            target: string;
            range: number;
            telegramChatId?: string | undefined;
            title?: string | undefined;
            backgroundImage?: File | undefined;
        } | undefined>>;
        setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    }) => JSX.Element
    }
  } = {
    twitter: {
      follow: TwitterFollowRequirements,
      retweet: TwitterRequirements,
      mention: TwitterRequirements,
      hashtag: TwitterRequirements
    },
    telegram: {
      joingroup: TelegramJoinGroupRequirements,
      engage: TelegramEngageRequirements
    }
  }

export const FillRequirements = (props: {
  platform: "twitter" | "telegram";
  type: string;
  setRequirements: React.Dispatch<
    React.SetStateAction<
      | {
          target: string;
          range: number;
          title?: string;
          backgroundImage?: File;
        }
      | undefined
    >
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return FillRequirementsInputs[props.platform][props.type](props)
};
