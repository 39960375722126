import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { ScaleFade, VStack, HStack, IconButton, Text, Heading, Button, Image, Link, useDisclosure, Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, Alert, AlertIcon, AlertTitle, Spinner, useToast } from "@chakra-ui/react";
import { Campaings } from "../../config/campaings";
import { Project } from "../../types/project";
import TargetIcon from '../../assets/icons/target.png'
import PeeperIcon from '../../assets/icons/peeper.png'
import TGIcon from '../../assets/icons/telegram.png'
import XIcon from '../../assets/icons/twitter.svg'
import { ConnectedWallet } from "../connected-wallet";
import { useApprove, usePeeperAllowance, usePeeperBalance } from "../../services/PeeperToken";
import { useAccount, useWaitForTransaction } from "wagmi";
import { DEFAULT_CURRENCY, PEEPER_CLAIMER_ADDRESS } from "../../config/constants";
import { useEffect, useState } from "react";
import { EthToWei } from "../../utils";
import { useDeposit } from "../../services/PeeperClaimer";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, firestore, storage } from "../../firebase";
import { Campaign } from "../../types/campaign";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const ConfirmCampaing = (props: {
    platform: "twitter" | "telegram",
    type: string
    requirements: {
        target: string;
        range: number;
        telegramChatId?: string,
        title?: string | undefined;
        backgroundImage?: File | undefined;
        creatorTwitterName?: string
        creatorTwitterUsername?: string
        creatorTwitterProfilePhoto?: string 
    }
    project: Project
    projectId: string
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}) => {
    
    const campaignPrice = Number(((Campaings[props.platform][props.type].pricePerJoin * props.requirements.range) * 1.1).toFixed(0))

    const DepositCheck = () => {
        const [nonce] = useState(Math.floor(Math.random() * 9999999))
        const {write, data, isLoading} = useDeposit({tokenAmount: EthToWei(campaignPrice), nonce: nonce})
        const { isOpen, onOpen, onClose } = useDisclosure()
        const toast = useToast()
        const navigate = useNavigate();

        // IMPORTANT: CHANGE THIS TO BACKEND
        const createCampaign = async (hash: string) => {
            onOpen()
            let campaignImage = props.project.image
            // send to service to create the campaign
            if(props.requirements.backgroundImage) {
                const storageRef = ref(storage, `/projects/${props.projectId}/${props.requirements.backgroundImage.name}`)
                await uploadBytes(storageRef, props.requirements.backgroundImage);
                campaignImage = await getDownloadURL(storageRef)
            }
            const campaign: Campaign = {
                image: campaignImage,
                platform: props.platform,
                projectId: props.projectId,
                range: props.requirements.range,
                telegramChatId: props.requirements.telegramChatId ?? null,
                status: 'In review',
                target: props.requirements.target,
                type: props.type,
                isAvailable: true,
                title: props.requirements.title ?? "",
                paymentTransactionHash: hash,
                creatorId: auth.currentUser?.uid ?? "",
                transactionNonce: nonce,
                createdAt: new Date(),
                participantsCount: 0,
                prizeAmount: Campaings[props.platform][props.type].pricePerJoin,
                currencyId: DEFAULT_CURRENCY.id,
                currencySymbol: DEFAULT_CURRENCY.symbol,
                currencyNetwotkId: DEFAULT_CURRENCY.networkId,
                currencyImage: DEFAULT_CURRENCY.image,
                currencyAddress: DEFAULT_CURRENCY.contractAddress,
                creatorTwitterName: props.requirements.creatorTwitterName ?? null,
                creatorTwitterProfilePhoto: props.requirements.creatorTwitterProfilePhoto ?? null,
                creatorTwitterUsername: props.requirements.creatorTwitterUsername ?? null
            }
            const cref = doc(firestore, 'campaigns', hash)
            await setDoc(cref, campaign)
            onClose()
            toast({
                title: 'Quest created!!',
                description: 'Your quest is in review and soon will be published to all PEEPER',
                isClosable: true
            })
            return navigate('/myprojects/'+props.projectId)
        }

        useEffect(() => {
            if(data && data.hash) {
                createCampaign(data.hash)
            }
        }, [data])
    
        const handleTransfer = () => {
            if(write) write()
        }
    
        return (
            <>
            <Modal isOpen={isOpen} onClose={() => {}}>
                <ModalOverlay />
                <ModalContent bg={'gray.700'} color={'white'}>
                <ModalHeader>Creating your Quest</ModalHeader>
                <ModalBody>
                    <VStack spacing={10} w={'100%'} justifyContent={'center'} alignItems={'center'}>
                        <Alert color={'black'} borderRadius={10} status="warning">
                            <AlertIcon />
                            <AlertTitle>Do not close this window until the Quest creation ends</AlertTitle>
                        </Alert>
                        <Spinner color="white" size={'lg'}/>
                        <Text color={'white'}>Get a coffe and relax</Text>
                    </VStack>
                </ModalBody>
                </ModalContent>
            </Modal>
            <Button onClick={handleTransfer} isLoading={isLoading} w={'100%'} colorScheme="yellow">
                Transfer {campaignPrice} to quest contract
            </Button>
            </>
        )
    }
    
    const AllowanceCheck = () => {
        const {address} = useAccount()
        const {data, isLoading, refetch} = usePeeperAllowance({owner: address ?? "", spender: PEEPER_CLAIMER_ADDRESS})
        const {write, isLoading: TransactionLoading, data: TransactionData} = useApprove({spender: PEEPER_CLAIMER_ADDRESS, value: EthToWei(campaignPrice).toString()})
        const {isLoading: ConfirmLoading} = useWaitForTransaction({
            hash: TransactionData?.hash
          })
    
        useEffect(() => {
            const intervalId = setInterval(() => {
              refetch();
            }, 1000);
            return () => clearInterval(intervalId);
          }, []);
    
          const handleApprove = () => {
            if(write) write();
          }
    
        if(data && BigInt(data as string) >= EthToWei(campaignPrice)) {
            return <DepositCheck  />
        }
    
        return (
            <Button onClick={handleApprove} isLoading={isLoading || TransactionLoading || ConfirmLoading} w={'100%'} colorScheme="yellow">
                Approve {campaignPrice} $PEEPER
            </Button>
        )
    }

    const BalanceCheck = () => {
        const {address} = useAccount()
        const {data, isLoading} = usePeeperBalance({account: address ?? ""})

        if(data && BigInt(data as string) >= EthToWei(campaignPrice)) {
            return <AllowanceCheck  />
        }

        return (
            <Button isDisabled isLoading={isLoading} w={'100%'} colorScheme="yellow">
               Not enough balance to create this quest
            </Button>
        )
    }
    
    const ActionButton = () => {
    
        return (
            <ConnectedWallet networkId={Number(DEFAULT_CURRENCY.networkId)}>
                <BalanceCheck />
            </ConnectedWallet>
        )
    }

    return (
        <ScaleFade initialScale={0.8} in={true}>
        <VStack color={'white'} w={'100%'} spacing={2} minH={'10em'} alignItems={'flex-start'} minW={'20em'}>
            <HStack w={'100%'} justifyContent={'flex-start'}>
                <IconButton variant={'outline'} size="sm" color={'white'} onClick={() => props.setActiveStep(2)} icon={<ArrowBackIcon />} aria-label="back"/>
            </HStack>
            <HStack>
            {props.requirements.title &&
                <Heading size={'lg'}>{props.requirements.title} </Heading>
            }
            <Heading size={'lg'}>On</Heading>
            <Image bg={'white'} borderRadius={10} padding={0} src={props.platform == 'twitter' ? XIcon : TGIcon} w={10} h={10} />
            </HStack>
            <VStack
                  w={"100%"}
                  alignItems={'flex-start'}
                  justifyContent={'space-between'}
                  minH={'15em'}
                  borderRadius={10}
                  overflow={"hidden"}
                  padding={2}
                  bgSize={'cover'} 
                  bgPosition={'center'}
                  bgImage={props.requirements.backgroundImage ? URL.createObjectURL(props.requirements.backgroundImage) : `url(${props.project.image})`}
                >
                </VStack>
                <HStack padding={1} alignItems={'center'} justifyContent={'flex-start'} bg={'gray.700'} borderRadius={10}>
                    <Image src={Campaings[props.platform][props.type].icon} w={10} h={10} />
                    <Heading size={'md'}>{Campaings[props.platform][props.type].previewMessage}:</Heading>
                        <Heading size={'md'}>{props.requirements.target} {' '}</Heading>
                </HStack>

                <HStack padding={1} alignItems={'center'} bg={'gray.700'} borderRadius={10}>
                <Image src={TargetIcon} w={10} h={10} />
                    <Heading size={'md'}>Hit:</Heading>
                    <Heading size={'md'}>{props.requirements.range} Peepers</Heading>
                </HStack>

                <HStack padding={1} alignItems={'center'} bg={'gray.700'} borderRadius={10}>
                <Image src={PeeperIcon} w={10} h={10} />
                    <Heading size={'md'}>Reward:</Heading>
                    <Heading size={'md'}>{Campaings[props.platform][props.type].pricePerJoin} $PEEPER</Heading>
                </HStack>

                <ActionButton />
        </VStack>
    </ScaleFade>
    )
}