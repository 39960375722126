import {
    Box,
    HStack,
    VStack,
    Image,
    Text,
    Button,
    Card,
    CardHeader,
    CardBody,
    Flex,
    Input,
    useToast,
    CardFooter,
    Link,
  } from "@chakra-ui/react";
  import Logo from "../assets/illustration.png";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { DocumentData, DocumentReference, doc, getDoc } from "firebase/firestore";
import { auth, firestore, service } from "../firebase";
import { Invite } from "../types/invite";

export const InviteCodePage = (props: {setDbUser: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const [inviteCode, setInviteCode] = useState<string>()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  
  const createUser = async (invite: Invite, ref: DocumentReference<DocumentData, DocumentData>) => {
    const resp = await service.createUserByInvite({inviteCode, name: auth.currentUser?.displayName ?? "anon"})
    if(!resp.data) {
        toast({
            title: 'Invalid invite',
            description: 'Sorry, we can`t find this invitation',
            status: 'error',
            isClosable: true
        })
        return
    }
    props.setDbUser(true)
}

  const checkInviteCode = async () => {
    if(!inviteCode || inviteCode.length < 10) return
    setLoading(true)
    try {
        const docRef = doc(firestore, 'invites', inviteCode)
        const snap = await getDoc(docRef)
        if(!snap.exists()) {
            toast({
                title: 'Invalid invite',
                description: 'Sorry, we can`t find this invitation',
                status: 'error',
                isClosable: true
            })
            throw new Error()
        }
        const data = snap.data() as Invite
        if(!data.isValid) {
            toast({
                title: 'Invalid expired',
                description: 'This invitation has already reached its full capacity for use',
                status: 'error',
                isClosable: true
            })
            throw new Error()
        }
        await createUser(data, docRef)
    } catch (error) {
        setLoading(false)
        return
    }
    setLoading(false)
}

  return (
        <>
        <VStack
        minH={"100vh"}
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
          <HStack wrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
            <Box w={"20em"} h={"20em"}>
              <Image src={Logo} />
            </Box>
            <Card bg="gray.700" w={'20em'}>
                <CardHeader>
                <Text fontFamily={'gotham-rounded-bold'} color={'whitesmoke'} fontSize={'larger'}>
                    Enter with your invite code:
                    </Text>
                </CardHeader>
                <CardBody >
                <Flex direction={'column'} h={'100%'} gap={'10px'} justifyContent={'center'}  borderRadius={10} >
                    <Input 
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                    _placeholder={{ opacity: 1, color: 'gray.300' }} color={'white'} placeholder="Your invite code"/>
                    <Button
                    onClick={checkInviteCode}
                    isDisabled={!inviteCode || inviteCode.length < 10}
                    isLoading={loading}
                    rightIcon={<ArrowForwardIcon />} colorScheme='pink' variant={'solid'}>Join</Button>
                </Flex>
                </CardBody>
                <CardFooter>
                    <Text color={'white'} fontFamily={'gotham-rounded-bold'}>
                    Don't have an invitation? ask for one in our {' '}
                    <Link target="_blank" color='teal.500' href="https://t.me/peeper_mint_community">Telegram community</Link>
                    </Text>
                </CardFooter>
                </Card>
          </HStack>
      </VStack>
      </>
    )
}