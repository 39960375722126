import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { HomePage } from './pages/Home'
import { MyProjectsPage } from './pages/MyProjects'
import { ProjectPage } from './pages/Project'
import { NewCampaignPage } from './pages/NewCampaing'
import { MyEarningsPage } from './pages/MyEarnings'
import { QuestPage } from './pages/Quest'
import { LoginPage } from './pages/Login'
import { User, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth, firestore } from './firebase'
import { LoadingPage } from './pages/Loading'
import { getDoc, doc } from 'firebase/firestore'
import { InviteCodePage } from './pages/InviteCode'

const Logged = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/myprojects',
        element: <MyProjectsPage />
    },
    {
        path: '/myprojects/:projectId',
        element: <ProjectPage/>
    },
    {
        path: '/newcampaign/:projectId',
        element: <NewCampaignPage />
    },
    {
        path: '/quest/:questId',
        element: <QuestPage />
    },
    {
        path: '/myearnings',
        element: <MyEarningsPage />
    }
])

const NotLogged = createBrowserRouter([
    {
        path: '/',
        element: <LoginPage />
    },
    {
        path: '/quest/:questId',
        element: <QuestPage />
    },
])

const SubRouter = () => {
    const [dbUser, setDbUser] = useState(false)
    const [loading, setLoading] = useState(true)
  
    const getUser = async () => {
      const userUid = auth.currentUser?.uid ?? ""
      const snap = await getDoc(doc(firestore, 'users', userUid))
      if(snap.exists() && snap.data().isActive) setDbUser(true)
      setLoading(false)
    }
  
    useEffect(() => {
      getUser()
    }, [])
  
    if(loading) return <LoadingPage />
  
    if(!dbUser) return <InviteCodePage setDbUser={setDbUser} />
  
    return <RouterProvider router={Logged} />
  }

export const AppEntry = () => {
    const [user, setUser] = useState<User | null | undefined>()
    
    onAuthStateChanged(auth, (user) => {
        if(!user) setUser(null)
        if(user) setUser(user)
    })
    
    if(user === undefined) return <LoadingPage />
    
    if(!user) return <RouterProvider router={NotLogged} />
    
    return <SubRouter />      
}