import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'
import {getFunctions, connectFunctionsEmulator, httpsCallable} from 'firebase/functions'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBXSmbW3Bu_CRy8riFfmJGwtkY_oza0UgM",
    authDomain: "peeper-70d09.firebaseapp.com",
    projectId: "peeper-70d09",
    storageBucket: "peeper-70d09.appspot.com",
    messagingSenderId: "598447339130",
    appId: "1:598447339130:web:278923aca139bef4072dc3"
  };  

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore();
// connectFirestoreEmulator(firestore, '0.0.0.0', 5002)
const functions = getFunctions();
// connectFunctionsEmulator(functions, '0.0.0.0', 5001)
export const service = {
    createUserByInvite:  httpsCallable(functions, 'createUserByInvite'),
    createUserInvite: httpsCallable(functions, 'createUserInvite'),
    createTelegramConnectionToken: httpsCallable(functions, 'createTelegramConnectionToken'),
    createClaimSignature: httpsCallable(functions, 'createClaimSignature'),
    updateClaimTx: httpsCallable(functions, 'updateClaimTx'),
    twitter: {
      joinPostCampaign: httpsCallable(functions, 'joinPostCampaign'),
      joinRetweetCampaign: httpsCallable(functions, 'joinRetweetCampaign'),
      joinFollowCampaign: httpsCallable(functions, 'joinFollowCampaign'),
      getMyUserId: httpsCallable(functions, 'getMyUserId')
    }
}
export const storage = getStorage(app)
export default app