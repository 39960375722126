import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, HStack, Heading, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Campaign } from "../../types/campaign"
import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { auth, firestore } from "../../firebase"
import { JoinToCampaing } from "../join-to-campaing"

export const QuestParticipated = () => {
    const [campaigns, setCampaigns] = useState<Campaign[]>()

    useEffect(() => {
        const userCampaigns = collection(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns');
        const unsub = onSnapshot(userCampaigns, async (snaps) => {
            const participatedIds = snaps.docs.map(d => d.id);
            const campaingsRef = collection(firestore, 'campaigns')
            if(participatedIds.length > 0) {
                const q = query(campaingsRef,
                    where('paymentTransactionHash', 'in', participatedIds ?? []), limit(50), orderBy('createdAt', 'desc'))
                const snap = await getDocs(q)
                setCampaigns(snap.docs.map(d => d.data() as Campaign))
            }
        });
        return () => unsub()
    }, [])

    return (
        <VStack spacing={5} w={'100%'} alignItems={'flex-start'}>
            <Heading color={'white'}> Quests I've taken part in </Heading>
            <HStack w={'100%'} justifyContent={'flex-start'} wrap={'wrap'} spacing={2}>
            {!campaigns &&
                <Box>
                <Alert flexWrap={'wrap'} borderRadius={20} status="info">
                    <AlertIcon />
                    <AlertTitle>Join to some quests</AlertTitle>
                    <AlertDescription>The quests that you participated will apper on here</AlertDescription>
                </Alert>
                </Box>
            }
            {campaigns?.map(c => {
                return (
                    <JoinToCampaing key={c.paymentTransactionHash} campaign={c} />
                )
            })}
            </HStack>
        </VStack>
    )
}