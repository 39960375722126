import { Card, CardBody, HStack, Heading, Image, VStack } from "@chakra-ui/react"
import { doc, onSnapshot, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { auth, firestore } from "../../firebase"
import { DbUser } from "../../types/user"
import TGIcon from '../../assets/icons/telegram.png'
import XIcon from '../../assets/icons/twitter.svg'
import WalletIcon from '../../assets/icons/wallet.svg'
import { useAccount } from "wagmi"
import { ConnectedWallet } from "../connected-wallet"
import { ConnectTelegram } from "./connect-telegram"

export const UserInfoCard = () => {
    const [userData, setUserData] = useState<DbUser>()
    const {address} = useAccount()

    useEffect(() => {
        const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "")
        const unsub = onSnapshot(ref, (snap) => {
            const data = snap.data() as DbUser
            if(data) {
                // if(!data.reLogged) {
                //     updateDoc(ref, {reLogged: true}).then(() => {
                //         auth.signOut()
                //     })
                // }
                setUserData(data )
            }
        })
        return () => unsub()
    }, [])

    const TelegramInfo = () => {
        if(!userData?.telegramUserId) {
            return <ConnectTelegram />
        }
        return <HStack alignItems={'center'}>
        <Image borderRadius={5} src={TGIcon} w={8} h={8}/>
        <Heading color={'white'} size={'sm'}>@{userData?.telegramUserName}</Heading>
    </HStack> 
    }

    const WalletInfo = () => {
        return <ConnectedWallet>
            <HStack alignItems={'center'}>
        <Image borderRadius={5} src={WalletIcon} w={8} h={8}/>
        <Heading color={'white'} size={'sm'}> {`${address?.slice(0, 5)}...${address?.slice(address.length - 5)}`}</Heading>
    </HStack> 
        </ConnectedWallet>
    }

    return (
        <Card bg={'gray.700'} w={'22.5em'}>
            <CardBody>
                <VStack w={'100%'} alignItems={'flex-start'}>
                <Heading color={'white'} size={'md'}>Account Info</Heading>
                    <HStack alignItems={'center'}>
                        <Image borderRadius={5} bg={'white'} src={XIcon} w={8} h={8}/>
                    <Heading color={'white'} size={'sm'}>{userData?.name}</Heading>
                    </HStack>
                    <TelegramInfo />
                    <WalletInfo />
                </VStack>
            </CardBody>
        </Card>
    )
}