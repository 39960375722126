import { Currency } from "../types/currency"

// Testnet
// export const PEEPER_TOKEN_ADDRESS='0x35F1548532D55B020A56B7c78315EdC9d58F5523'
// export const PEEPER_CLAIMER_ADDRESS='0x1aA4F6CAF0FA90DfF167D9F12ebacd533f548f77'
// export const DEFAULT_CURRENCY: Currency={
//     id: 'HuVL83fBXNSe2nKagC9O',
//     name: "Peeper Sepolia",
//     symbol: "SepoliaPET",
//     networkId: "11155111",
//     image: "https://firebasestorage.googleapis.com/v0/b/peeper-70d09.appspot.com/o/projects%2F72e387c6-9e21-48e3-be0d-ab82d3464f7d%2F_1peeper-icon.png?alt=media&token=54b2eb5e-90c6-4c0f-9f6e-a2f47bc7e60c",
//     contractAddress: "0x35F1548532D55B020A56B7c78315EdC9d58F5523",
//     type: 'ERC20'
// }

// Production
export const PEEPER_TOKEN_ADDRESS='0x534D685E2ab024f5e454c979256441837fdd2dF7'
export const PEEPER_CLAIMER_ADDRESS='0x71c388dE20Ba7178C12420806399A17E13621Cc7'
export const DEFAULT_CURRENCY: Currency={
    id: 'HuVL83fBXNSe2nKagC9O',
    name: "Peeper",
    symbol: "PEEPER",
    networkId: "56",
    image: "https://firebasestorage.googleapis.com/v0/b/peeper-70d09.appspot.com/o/projects%2F72e387c6-9e21-48e3-be0d-ab82d3464f7d%2F_1peeper-icon.png?alt=media&token=54b2eb5e-90c6-4c0f-9f6e-a2f47bc7e60c",
    contractAddress: "0x534D685E2ab024f5e454c979256441837fdd2dF7",
    type: 'ERC20'
}