import {
  Box,
  HStack,
  VStack,
  Image,
  Text,
  Heading,
  Button,
  useToast,
} from "@chakra-ui/react";
import Logo from "../assets/illustration.png";
import { NotLoggedNavBar } from "../components/navbars";
import { LoginWithXButton } from "../components/login-with-x";
import { QuestsSlider } from "../components/quests-slider";
export const LoginPage = () => {

  return (
    <>
    <NotLoggedNavBar />
    <VStack
      minH={"100vh"}
      w={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
        <HStack wrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
          <Box w={"20em"} h={"20em"}>
            <Image src={Logo} />
          </Box>
          <Box
            w={"20em"}
            h={"20em"}
            color={"white"}
            bg={"gray.700"}
            borderRadius={10}
            padding={5}
          >
            <VStack h={"100%"} justifyContent={"space-between"}>
              <Heading>Welcome to Peeper</Heading>
              <Text>A SocialFi platform to empower micro-influence</Text>
                <LoginWithXButton />
            </VStack>
          </Box>
        </HStack>
        <QuestsSlider />
    </VStack>
    </>
  );
};
