import {
  Box,
  Flex,
  HStack,
  Image,
  IconButton,
  Spacer,
  Link,
  VStack,
  useDisclosure,
  Button,
  Text,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon, StarIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Logo from "../../assets/icons/PeeperLogo.svg";
import { auth } from "../../firebase";
import HomeIcon from "../../assets/icons/home.svg";
import ProjectIcon from "../../assets/icons/project.svg";
import { NotLoggedNavBar } from "./not-logged";
import { Link as ReactLink } from "react-router-dom";

export const LoggedNavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const RenderItensMobile = ({ children }: { children: any }) => {
    if (isMobile) {
      return <VStack>{children}</VStack>;
    }
    return (
      <HStack spacing={"10"} alignItems={"baseline"}>
        {children}
      </HStack>
    );
  };

  const AccountMenu = () => {
    return (
      <Menu>
        <MenuButton
          bg={"inherit"}
          as={Button}
          rightIcon={<ChevronDownIcon color={"white"} />}
        >
          <Image
            rounded={"full"}
            src={auth.currentUser?.photoURL ?? ""}
            w={6}
            h={6}
          />
        </MenuButton>
        <MenuList>
          <MenuItem
            color={"black"}
            onClick={() => {
              auth.signOut();
            }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  if (!auth.currentUser) return <NotLoggedNavBar />;

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      color="white"
    >
      <Link as={ReactLink} to="/">
      <Flex align="center">
        <Image src={Logo} />
      </Flex>
      </Link>

      {isMobile ? (
        <IconButton
          display={{ base: "block", md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          icon={<HamburgerIcon />}
          aria-label={""}
        />
      ) : (
        <Spacer />
      )}

      <Box
        display={{ base: isOpen ? "block" : "none", md: "flex" }}
        width={{ base: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <HStack
          w={"100%"}
          spacing={4}
          align="center"
          justify={{ base: "center", md: "flex-end" }}
        >
          <RenderItensMobile>
              <Link as={ReactLink} to="/">
                <HStack alignItems={"baseline"} justifyContent={"center"}>
                  <Image src={HomeIcon} w={4} h={4} color={"white"} />
                  <Text
                    fontFamily={"gotham-rounded-bold"}
                    color={"whitesmoke"}
                    fontSize={"larger"}
                  >
                    Home
                  </Text>
                </HStack>
              </Link>
              <Link as={ReactLink} to="/myprojects">
                <HStack alignItems={"baseline"} justifyContent={"center"}>
                  <Image src={ProjectIcon} w={4} h={4} color={"white"} />
                  <Text
                    fontFamily={"gotham-rounded-bold"}
                    color={"whitesmoke"}
                    fontSize={"larger"}
                  >
                    My Projects
                  </Text>
                </HStack>
              </Link>
              <Link as={ReactLink} to="/myearnings">
                <HStack alignItems={"baseline"} justifyContent={"center"}>
                  <StarIcon w={4} h={4} color={"white"}/>
                  <Text
                    fontFamily={"gotham-rounded-bold"}
                    color={"whitesmoke"}
                    fontSize={"larger"}
                  >
                    My Earnings
                  </Text>
                </HStack>
              </Link>
            <AccountMenu />
          </RenderItensMobile>
        </HStack>
      </Box>
    </Flex>
  );
};
