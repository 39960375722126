import { HStack, Heading, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Campaign } from "../../types/campaign"
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { auth, firestore } from "../../firebase"
import { JoinToCampaing } from "../join-to-campaing"

export const AvailableQuests = () => {
    const [campaigns, setCampaigns] = useState<Campaign[]>()
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
    
      useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    useEffect(() => {
        const userCampaigns = collection(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns');
        const unsub = onSnapshot(userCampaigns, async (snaps) => {
            const participatedIds = snaps.docs.map(d => d.id);
            const campaingsRef = collection(firestore, 'campaigns')
            if(participatedIds.length > 0) {
                const q = query(campaingsRef,
                    where('isAvailable', '==', true),
                    where('paymentTransactionHash', 'not-in', participatedIds ?? []), orderBy('paymentTransactionHash','desc'), orderBy('createdAt', 'desc'))
                const snap = await getDocs(q)
                setCampaigns(snap.docs.map(d => d.data() as Campaign))
            } else {
                const q = query(campaingsRef,
                    where('isAvailable', '==', true))
                const snap = await getDocs(q)
                setCampaigns(snap.docs.map(d => d.data() as Campaign))
            }
        });
        return () => unsub()
    }, [])

    return (
        <VStack spacing={5} w={'100%'} alignItems={'flex-start'}>
            <Heading color={'white'}> Available Quests </Heading>
            <HStack w={'100%'} justifyContent={ isMobile ? 'center' : 'flex-start'} wrap={'wrap'} spacing={3}>
            {campaigns?.map(c => {
                return (
                    <JoinToCampaing key={c.paymentTransactionHash} campaign={c} />
                )
            })}
            </HStack>
        </VStack>
    )
}