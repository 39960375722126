import { useEffect, useState } from "react";
import { Campaign } from "../../types/campaign";
import {
  Button,
  Heading,
  Textarea,
  VStack,
  useToast,
  Text,
  Image,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  FormControl,
  FormHelperText,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import { auth, firestore, service } from "../../firebase";
import XIcon from "../../assets/icons/twitter-white.svg";
import HeartIcon from "../../assets/icons/heart.png"
import { useNavigate } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { LoginWithXButton } from "../login-with-x";
import { DbUser } from "../../types/user";
import { ConnectTelegram } from "../user-info/connect-telegram";

const TwitterMention = (campaign: Campaign) => {
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth.currentUser) {
      const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns', campaign.paymentTransactionHash);
      getDoc(ref).then(snap => {
          if(snap.exists()){ 
              setIsValid(false)
          } else {
              setIsValid(true)
          }
      })
    }
  }, [auth.currentUser])

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await service.twitter.joinPostCampaign({
        campaignId: campaign.paymentTransactionHash,
        text: `${campaign.target} ${value.trim()}`,
      });
      toast({
        title: "Success!",
        description:
          "Your tweet was sended and your reward is available to claim",
        status: "success",
      });
      // redirect user to earnings page
      return navigate("/myearnings");
    } catch (error) {
      toast({
        title: "Try again in some minutes",
        description:
          "We are facing some problems due the amount of requests in that moment, try again in some minutes",
        status: "error",
      });
    }

    setIsLoading(false);
  };
  return (
    <VStack spacing={4} marginTop={2} color={"white"}>
      <Heading size={"sm"}>
        Make a tweet mention {campaign.target} and get your reward
      </Heading>
      <Text color={'orange'}>You need to create a message of at least 10 characters mention {campaign.target}</Text>
      <FormControl>
      <Textarea
        value={`${campaign.target} ${value}`}
        onChange={(e) => {
          if ((`${campaign.target} ${value}`).length <= 280) setValue(e.target.value.replace(campaign.target, "").trimStart())
        }
        }
      />
      <FormHelperText color={'white'}>{(`${campaign.target} ${value}`).length ?? 0}/280</FormHelperText>
      </FormControl>
      {isValid ? 
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        isDisabled={value.trim().length < 10}
        w={"100%"}
        colorScheme="blue"
        rightIcon={<Image src={XIcon} w={6} h={6} />}
      >
        Tweet
      </Button>
        :
        auth.currentUser ? 
        <Button
        isLoading={isLoading}
        isDisabled
        w={"100%"}
        colorScheme="blue"
        rightIcon={<Image src={XIcon} w={6} h={6} />}
      >
        You already participate in this campaign
      </Button>
      : <LoginWithXButton />
    }
    </VStack>
  );
};

const TwitterHashtag = (campaign: Campaign) => {
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth.currentUser) {
    const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns', campaign.paymentTransactionHash);
    getDoc(ref).then(snap => {
        if(snap.exists()){ 
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    })
  }
  }, [auth.currentUser])

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await service.twitter.joinPostCampaign({
        campaignId: campaign.paymentTransactionHash,
        text: `${campaign.target} ${value.trim()}`,
      });
      toast({
        title: "Success!",
        description:
          "Your tweet was sended and your reward is available to claim",
        status: "success",
      });
      // redirect user to earnings page
      return navigate("/myearnings");
    } catch (error) {
      toast({
        title: "Try again in some minutes",
        description:
          "We are facing some problems due the amount of requests in that moment, try again in some minutes",
        status: "error",
      });
    }

    setIsLoading(false);
  };
  return (
    <VStack spacing={4} marginTop={2} color={"white"}>
      <Heading size={"sm"}>
        Make a tweet with {campaign.target} and get your reward
      </Heading>
      <Text color={'orange'}>You need to create a message of at least 10 characters with the hashtag {campaign.target}</Text>
      <FormControl>
      <Textarea
        value={`${campaign.target} ${value}`}
        onChange={(e) => {
            if ((`${campaign.target} ${value}`).length <= 280) setValue(e.target.value.replace(campaign.target, "").trimStart())
        }
        }
      />
            <FormHelperText color={"white"}>
        {(`${campaign.target} ${value}`).length ?? 0}/280
      </FormHelperText>
      </FormControl>
      
      {isValid ? 
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        isDisabled={value.trim().length < 10}
        w={"100%"}
        colorScheme="blue"
        rightIcon={<Image src={XIcon} w={6} h={6} />}
      >
        Tweet
      </Button>
        : 
        auth.currentUser ? 
        <Button
        isLoading={isLoading}
        isDisabled
        w={"100%"}
        colorScheme="blue"
        rightIcon={<Image src={XIcon} w={6} h={6} />}
      >
        You already participate in this campaign
      </Button>
      : <LoginWithXButton />
    }
    </VStack>
  );
};

const Retweet = (campaign: Campaign) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth.currentUser) {
    const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns', campaign.paymentTransactionHash);
    getDoc(ref).then(snap => {
        if(snap.exists()){ 
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    })
  }
  }, [auth.currentUser])

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const regex = /\/status\/(\d+)/;
      const match = campaign.target.match(regex);
      if (!match) {
        throw new Error("Invalid link");
      }
      const tweetId = match[1];
      await service.twitter.joinRetweetCampaign({
        campaignId: campaign.paymentTransactionHash,
        tweet: tweetId,
      });
      toast({
        title: "Success!",
        description:
          "Your tweet was sended and your reward is available to claim",
        status: "success",
      });
      // redirect user to earnings page
      return navigate("/myearnings");
    } catch (error) {
      toast({
        title: "Try again in some minutes",
        description:
          "We are facing some problems due the amount of requests in that moment, try again in some minutes",
        status: "error",
      });
    }

    setIsLoading(false);
  };

  return (
    <VStack spacing={4} marginTop={2} color={"white"}>
      <Heading size={"md"}>
        Retweet{" "}
        <Link href={campaign.target} isExternal>
          Quest <ExternalLinkIcon />
        </Link>
      </Heading>
      <Text>And claim your reward</Text>
      {isValid ?
      
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        w={"100%"}
        colorScheme="blue"
        rightIcon={<Image src={XIcon} w={6} h={6} />}
      >
        Retweet
      </Button>
            :       
            auth.currentUser ?   <Button
      isDisabled
      w={"100%"}
      colorScheme="blue"
      rightIcon={<Image src={XIcon} w={6} h={6} />}
    >
      You already participated in this quest
    </Button> : <LoginWithXButton />}
    </VStack>
  );
};

const Follow = (campaign: Campaign) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth.currentUser) {
    const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns', campaign.paymentTransactionHash);
    getDoc(ref).then(snap => {
        if(snap.exists()){ 
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    })
  }
  }, [auth.currentUser])

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await service.twitter.joinFollowCampaign({
        campaignId: campaign.paymentTransactionHash,
        targetUserId: campaign.target,
      });
      toast({
        title: "Success!",
        description:
          `You follow ${campaign.creatorTwitterName} and your reward is available to claim`,
        status: "success",
      });
      // redirect user to earnings page
      return navigate("/myearnings");
    } catch (error) {
      toast({
        title: "Try again in some minutes",
        description:
          "We are facing some problems due the amount of requests in that moment, try again in some minutes",
        status: "error",
      });
    }

    setIsLoading(false);
  };

  return (
    <VStack spacing={4} marginTop={2} color={"white"}>
      <Heading size={"md"}>
        Follow Profile{" "}
      </Heading>
      <HStack>
              {campaign.creatorTwitterProfilePhoto && 
                <Avatar name={campaign.creatorTwitterUsername ?? ""} src={campaign.creatorTwitterProfilePhoto} size={'md'} />
              }
              <VStack alignItems={'flex-start'}>
                <Heading size={'md'}> {campaign.creatorTwitterName} </Heading>
                <Heading size={'sm'}> @{campaign.creatorTwitterUsername} </Heading>
              </VStack>
            </HStack>
      <Text>And claim your reward</Text>
      {isValid ? 
      <Button
        isLoading={isLoading}
        onClick={handleClick}
        w={"100%"}
        colorScheme="pink"
        rightIcon={<Image src={HeartIcon} w={6} h={6} />}
      >
        Follow @{campaign.creatorTwitterUsername}
      </Button>
      :       
      auth.currentUser ? 
      <Button
      isDisabled
      w={"100%"}
      colorScheme="pink"
      rightIcon={<Image src={HeartIcon} w={6} h={6} />}
    >
      You already participated in this quest
    </Button> : <LoginWithXButton />}
    </VStack>
  );
}

const TelegramJoin = (campaign: Campaign) => {
    const [isValid, setIsValid] = useState(false)
    const [userData, setUserData] = useState<DbUser>()

    useEffect(() => {
        if(auth.currentUser) {
        const ref = doc(firestore, 'users', auth.currentUser?.uid ?? "", 'campaigns', campaign.paymentTransactionHash);
        getDoc(ref).then(snap => {
            if(snap.exists()){ 
                setIsValid(false)
            } else {
                setIsValid(true)
            }
        })
        const refUser = doc(firestore, 'users', auth.currentUser?.uid ?? "")
        const unsub = onSnapshot(refUser, (snap) => {
            if(snap.data()) setUserData(snap.data() as DbUser)
        })
        return () => unsub()
      }
      }, [auth.currentUser])

    if(userData && !userData.telegramUserId) {
      return <ConnectTelegram />
    }

  return (
    <VStack color={"white"} alignItems={'flex-start'}>
      <Text color={'orange'}>🚨 You need to be logged into the platform and have your telegram connected to peeper before joining the group to earn the reward</Text>
      <Heading size={"md"}>
        1) Join{" "}
        <Link color={'yellow'} href={campaign.target} isExternal>
          Group <ExternalLinkIcon />
        </Link>
      </Heading>
      <Heading size={"md"}>
        2) Get back to Peeper and claim your reward!
      </Heading>
      {isValid ? 
        <></>
        : auth.currentUser ?
        <Alert borderRadius={10} color={'black'} status="info">
            <AlertIcon />
            <AlertTitle>You already participate in this quest</AlertTitle>
        </Alert>
        : <LoginWithXButton />
      }
    </VStack>
  );
};

const Components: any = {
  twitter: {
    mention: TwitterMention,
    hashtag: TwitterHashtag,
    retweet: Retweet,
    follow: Follow
  },
  telegram: {
    joingroup: TelegramJoin,
  },
};

export const QuestRenderInputs = (props: {
  platform: string;
  type: string;
  campaign: Campaign;
}) => {
  return Components[props.platform][props.type](props.campaign);
};
