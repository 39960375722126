import { EditIcon, LinkIcon, PlusSquareIcon } from "@chakra-ui/icons";
import {
  Card,
  CardBody,
  VStack,
  Icon,
  Heading,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Box,
  Image,
  InputGroup,
  InputLeftAddon,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import TGIcon from '../../assets/icons/telegram.png'
import InstagramIcon from '../../assets/icons/instagram.svg'
import XIcon from '../../assets/icons/twitter.svg'
import DiscordIcon from '../../assets/icons/discord.svg'
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, firestore, storage } from "../../firebase";
import {v4} from 'uuid';
import { doc, setDoc } from "firebase/firestore";
import { Project } from "../../types/project";

export const NewProject = (props: {project?: Project, projectId?: string}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File | string | undefined>(props.project?.image);
  const [name, setName] = useState<string | undefined>(props.project?.name);
  const [description, setDescription] = useState<string | undefined>(props.project?.description);
  const [website, setWebsite] = useState<string | undefined>(props.project?.website);
  const [telegram, setTelegram] = useState<string | undefined>(props.project?.telegram);
  const [twitter, setTwitter] = useState<string | undefined>(props.project?.twitter);
  const [discord, setDiscord] = useState<string | undefined>(props.project?.discord);
  const [instagram, setInstagram] = useState<string | undefined>(props.project?.instagram);

  const clearAllFields = () => {
    if(props.project) return
    setSelectedImage(undefined)
    setName(undefined)
    setDescription(undefined)
    setWebsite(undefined)
    setTelegram(undefined)
    setTwitter(undefined)
    setDiscord(undefined)
    setInstagram(undefined)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      // Aqui você pode adicionar validações ou pré-visualizações da imagem, se necessário
      setSelectedImage(file);
    }
  };

  const saveProject = async () => {
    if(!selectedImage || !name || !description) return
    // upload file to firebase storage
    setIsLoading(true)
    const projectId = props.projectId ?? v4();
    let imageUrl = props.project?.image ?? ""
    if(typeof selectedImage != 'string') {
      const storageRef = ref(storage, `/projects/${projectId}/${selectedImage.name}`)
      await uploadBytes(storageRef, selectedImage);
      imageUrl = await getDownloadURL(storageRef)
    }

    const projectRef = doc(firestore, 'projects', projectId);
    await setDoc(projectRef, {
        image: imageUrl,
        name,
        description,
        creatorId: auth.currentUser?.uid ?? "",
        twitter: twitter ?? "",
        telegram: telegram ?? "",
        website: website ?? "",
        instagram: instagram ?? "",
        discord: discord ?? "",
        createdAt: new Date()
    })
    setIsLoading(false)
    clearAllFields()
    onClose()
  }

  return (
    <>
    {
      props.project ? 
      <IconButton onClick={onOpen} size={'sm'} aria-label="edit-project" icon={<EditIcon />} />
      :
      <Card bg={"gray.700"} opacity={0.8} border={'1px solid white'} w={'15em'}>
      <CardBody>
        <Link onClick={onOpen}>
          <VStack justifyContent={"center"} h={'100%'} alignItems={"center"}>
            <Icon as={PlusSquareIcon} color={"white"} w={10} h={10} />
            <Heading color={"white"} size={"md"}>
              New Project
            </Heading>
          </VStack>
        </Link>
      </CardBody>
    </Card>
    }
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={'gray.700'}>
          <ModalHeader color={'white'}>{props.project ? 'Update' : 'Create new'} project</ModalHeader>
          <ModalCloseButton color={'white'}/>
          <ModalBody >
            <VStack spacing={2}>
            <FormControl isRequired>
              <FormLabel color={'white'}>Background Image</FormLabel>
              {selectedImage ? (
                <Box
                  w={"100%"}
                  minH={"5em"}
                  borderRadius={10}
                  overflow={"hidden"}
                >
                  <Image
                    src={typeof selectedImage == 'string' ? selectedImage : URL.createObjectURL(selectedImage)}
                    borderRadius={10}
                    alt="preview"
                    width="100%"
                    height="100%"
                    mb="2"
                  />
                  <HStack w={"100%"} justifyContent={"flex-end"}>
                    <Button
                      size={"sm"}
                      variant={"ghost"}
                      colorScheme="red"
                      onClick={() => setSelectedImage(undefined)}
                    >
                      {" "}
                      Discard Image{" "}
                    </Button>
                  </HStack>
                </Box>
              ) : (
                <Box w={"100%"} h={"5em"} bg={"gray.600"} borderRadius={10}>
                  <VStack
                    w={"100%"}
                    h={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Input
                    color={'white'}
                    borderColor={'gray.600'}
                      onChange={handleImageChange}
                      type="file"
                      accept="image/*"
                      placeholder="Select a image"
                    />
                  </VStack>
                </Box>
              )}
              <FormHelperText color={'white'}>Select a nice image</FormHelperText>
              </FormControl>

              <FormControl isRequired>
              <FormLabel color={'white'}>Name</FormLabel>
              <Input color={'white'} value={name} onChange={(e) => setName(e.target.value)} />
              <FormHelperText color={'white'}>The name of your project</FormHelperText>
              </FormControl>

              <FormControl isRequired>
              <FormLabel color={'white'}>Description</FormLabel>
              <Textarea
              color={'white'}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormHelperText color={'white'}>Describe your project purpose</FormHelperText>
              </FormControl>

              <FormControl>
              <FormLabel color={'white'}>Website</FormLabel>
              <InputGroup>
              <InputLeftAddon bg={'gray.700'}>
                <Icon as={LinkIcon} color={'white'}/>
              </InputLeftAddon>
              <Input color={'white'} value={website} onChange={(e) => setWebsite(e.target.value)} />
              </InputGroup>
              </FormControl>

            <FormControl>
            <FormLabel color={'white'}>Twitter (X)</FormLabel>
            <InputGroup>
            <InputLeftAddon bg={'gray.700'}>
            <Image src={XIcon} w={5} h={5}/>
            </InputLeftAddon>
            <Input color={'white'} value={twitter} onChange={(e) => setTwitter(e.target.value)} />
            </InputGroup>
            </FormControl>

              <FormControl>
              <FormLabel color={'white'}>Telegram</FormLabel>
              <InputGroup>
              <InputLeftAddon bg={'gray.700'}>
                <Image src={TGIcon} w={5} h={5}/>
              </InputLeftAddon>
              <Input color={'white'} value={telegram} onChange={(e) => setTelegram(e.target.value)} />
              </InputGroup>
              </FormControl>

              <FormControl>
              <FormLabel color={'white'}>Discord</FormLabel>
              <InputGroup>
              <InputLeftAddon bg={'gray.700'}>
                <Image src={DiscordIcon} w={5} h={5}/>
              </InputLeftAddon>
              <Input color={'white'} value={discord} onChange={(e) => setDiscord(e.target.value)} />
              </InputGroup>
              </FormControl>

              <FormControl>
              <FormLabel color={'white'}>Instagram</FormLabel>
              <InputGroup>
              <InputLeftAddon bg={'gray.700'}>
                <Image src={InstagramIcon} w={5} h={5}/>
              </InputLeftAddon>
              <Input color={'white'} value={instagram} onChange={(e) => setInstagram(e.target.value)} />
              </InputGroup>
              </FormControl>
              </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={"4"}>
              <Button variant="ghost" colorScheme="yellow" onClick={() => {clearAllFields(); onClose()}}>
                Discard
              </Button>
              <Button isLoading={isLoading} isDisabled={!selectedImage || !name || !description} colorScheme="green" mr={3} onClick={saveProject}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
