import { useEffect, useState } from "react";
import { Campaign } from "../../types/campaign";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";
import { JoinToCampaing } from "../join-to-campaing";
import { HStack, Heading, VStack } from "@chakra-ui/react";

export const QuestsSlider = () => {
    const [campaigns, setCampaigns] = useState<Campaign[]>()

    const loadQuests = async () => {
        const campaingsRef = collection(firestore, 'campaigns')
        const q = query(campaingsRef, limit(10), orderBy('createdAt', 'desc'))
        const snap = await getDocs(q)
        setCampaigns(snap.docs.map(d => d.data() as Campaign))
    }

    useEffect(() => {
        loadQuests()
    }, [])

    if(!campaigns) return <></>

    return (
        <VStack alignItems={'flex-start'} padding={10}>
        <Heading color={'white'} fontFamily={'gotham-rounded-bold'}>Latest Quests</Heading>
        <HStack wrap={'wrap'} spacing={10} w={'100%'} justifyContent={'space-between'}>
            {campaigns.map(c => <JoinToCampaing campaign={c} key={c.paymentTransactionHash}/>)}
        </HStack>
        </VStack>
      );
}