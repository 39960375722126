import { Box, Card, CardBody, CardHeader, Container, HStack, Heading, Icon, Image, Link, Text, VStack, createIcon, useToast } from "@chakra-ui/react"
import { doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { auth, firestore } from "../firebase"
import { Project } from "../types/project"
import { LoadingPage } from "./Loading"
import { NewProject } from "../components/new-project"
import { LinkIcon } from "@chakra-ui/icons"
import TGIcon from '../assets/icons/telegram.png'
import XIcon from '../assets/icons/twitter.svg'
import DiscordIcon from '../assets/icons/discord.svg'
import InstagramIcon from '../assets/icons/instagram.svg'
import { ProjectCampaignsList } from "../components/project-campaings"
import { LoggedNavBar } from "../components/navbars"

export const ProjectPage = () => {
    const {projectId} = useParams() as {projectId: string}
    const [project, setProject] = useState<Project>()
    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        const ref = doc(firestore, 'projects', projectId)
        const unsub = onSnapshot(ref, (snap) => {
            if(!snap.exists()) {
                toast({
                    title: 'Project not found',
                    isClosable: true,
                    duration: 3000,
                    status: 'error'
                })
                return navigate('/myprojects')
            }
            const data = snap.data() as Project
            if(data.creatorId !== auth.currentUser?.uid) {
                toast({
                    title: 'Project not found',
                    isClosable: true,
                    duration: 3000,
                    status: 'error'
                })
                return navigate('/myprojects')
            }
            setProject(data)
        })
        
        return () => unsub()
    }, [])

    if(!project) {
        return <LoadingPage />
    }

    return ( 
        <>
        <LoggedNavBar />
    <Container minW={'90%'} minH={'100vh'}>
    <HStack marginY={5} wrap={'wrap'} spacing={0} w={'100%'} justifyContent={'center'} alignItems={'stretch'}>
        <Box padding={2} bgImage={`url(${project.image})`} minH={'10em'} minW={'22.5em'} borderRadius={10} bgSize={'cover'} bgPosition={'center'}>
        <HStack w={'100%'} justifyContent={'flex-end'}>
                    <NewProject project={project} projectId={projectId}/>
                </HStack>
        </Box>
        <Card minW={'22.5em'} bg={"gray.700"}>
            <CardHeader>
                <Heading color={'white'}>{project.name}</Heading>
            </CardHeader>
            <CardBody>
                <Text color={'white'} size={'md'}>{project.description}</Text>
                {project.website && <Link color={'white'} href={project.website} isExternal>
                    <LinkIcon mx='2px' /> {project.website}
                </Link>}
                {project.telegram && <Link color={'white'} href={project.telegram} isExternal>
                <HStack>
                    <Image src={TGIcon} mx='2px' w={5} h={5}/>
                    <Text color={'white'}>{project.telegram}</Text>
                </HStack>
                </Link>}

                {project.twitter && <Link color={'white'} href={project.twitter} isExternal>
                <HStack>
                    <Image src={XIcon} mx='2px' w={5} h={5}/>
                    <Text color={'white'}>{project.twitter}</Text>
                </HStack>
                </Link>}

                {project.discord && <Link color={'white'} href={project.discord} isExternal>
                <HStack>
                    <Image src={DiscordIcon} mx='2px' w={5} h={5}/>
                    <Text color={'white'}>{project.discord}</Text>
                </HStack>
                </Link>}

                {project.instagram && <Link color={'white'} href={project.instagram} isExternal>
                <HStack>
                    <Image src={InstagramIcon} mx='2px' w={5} h={5}/>
                    <Text color={'white'}>{project.instagram}</Text>
                </HStack>
                </Link>}
            </CardBody>
        </Card>
    </HStack>
    <ProjectCampaignsList projectId={projectId}/>

</Container>
</>
    )
}