import { Box, Container, HStack, Heading, VStack } from "@chakra-ui/react"
import { Wallet } from "../components/wallet"
import { UserSectionHeader } from "../components/user-section-header"
import { InviteCodeCard } from "../components/invite-code"
import { UserInfoCard } from "../components/user-info"
import { AvailableQuests } from "../components/available-quests"
import { LoggedNavBar } from "../components/navbars"
import { AvailableToClaimCard } from "../components/available-to-claim"
import { QuestParticipated } from "../components/quests-participated"

export const HomePage = () => {
    return (
        <>
        <LoggedNavBar />
        <VStack w={'100%'} alignItems={'center'}>
            
        <Container minW={'80%'} paddingBottom={5} minH={'100vh'}>
            <VStack spacing={5}>
            <HStack marginY={5} w={'100%'} justifyContent={'flex-start'}>
                <UserSectionHeader />
            </HStack>
        <HStack wrap={'wrap'} w={'100%'} alignItems={'stretch'} justifyContent={'flex-start'}>
        <UserInfoCard />
        <Wallet />
        <InviteCodeCard />
        <AvailableToClaimCard />
        </HStack>
        <AvailableQuests />
        <QuestParticipated />
        </VStack>
        </Container>
        </VStack>
        </>
    )
}