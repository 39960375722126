import { Container, Heading } from "@chakra-ui/react"
import { MyProjectsList } from "../components/my-projects-list"
import { LoggedNavBar } from "../components/navbars"

export const MyProjectsPage = () => {

    return (
        <>
        <LoggedNavBar />
        <Container minW={'90%'} minH={'100vh'}>
            <Heading color={'white'} marginY={5}>My Projects</Heading>
            <MyProjectsList />
        </Container>
        </>
    )
}