import { useContractWrite, usePrepareContractWrite } from "wagmi"
import { PEEPER_CLAIMER_ADDRESS } from "../config/constants"
import PeeperClaimerBuild from '../abis/PeeperClaimer.json'

export const useDeposit = (props: {tokenAmount: bigint, nonce: number}) => {
    const {config} = usePrepareContractWrite({
        address: PEEPER_CLAIMER_ADDRESS,
        abi: PeeperClaimerBuild.abi,
        functionName: 'deposit',
        args: [props.tokenAmount, props.nonce]
    })

    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}
// uint256 amount, ERC20 tokenTarget, uint256 nonce, bytes memory signature
export const useClaim = (props: {amount: string, tokenTarget: string, nonce: number, signature: string}) => {
    const {config} = usePrepareContractWrite({
        address: PEEPER_CLAIMER_ADDRESS,
        abi: PeeperClaimerBuild.abi,
        functionName: 'claim',
        args: [props.amount, props.tokenTarget, props.nonce, props.signature]
    })

    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}