import { Button, Image, useToast } from "@chakra-ui/react"
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { auth, firestore } from "../../firebase";
import TwitterIcon from "../../assets/icons/twitter.svg";

export const LoginWithXButton = () => {
    const [loading, setLoading] = useState(false)
    const provider = new TwitterAuthProvider();
    const toast = useToast()

    const makeLogin = async () => {
        setLoading(true)
        try {
            const result = await signInWithPopup(auth, provider)
            const credential = TwitterAuthProvider.credentialFromResult(result)
            const token = credential?.accessToken
            const secret =  credential?.secret
            const ref = doc(firestore, 'users', result.user.uid)
            // try get user
            const userCheck = await getDoc(ref)
            if(userCheck.exists()) {
              await updateDoc(ref, {
                twitterAccessToken: token,
                twitterSecret: secret
              })
            } else {
              await setDoc(ref, {
                twitterAccessToken: token,
                twitterSecret: secret
              }, {merge: true})
            }
        } catch (error) {
            toast({
                title: 'Access denied',
                status: 'error',
                isClosable: true,
                duration: 9000
            })
        }
        setLoading(false)
    }

    
    return <Button w={'100%'} onClick={makeLogin} isLoading={loading} rightIcon={<Image src={TwitterIcon} w={8} h={8} />}>
    Login with
  </Button>
}