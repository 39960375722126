import { useContractRead, useContractWrite, usePrepareContractWrite } from "wagmi"
import { PEEPER_TOKEN_ADDRESS } from "../config/constants"
import PeeperTokenBuild from '../abis/PeeperToken.json'

export const usePeeperBalance = (props: {account: string}) => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_TOKEN_ADDRESS,
        abi: PeeperTokenBuild.abi,
        functionName: 'balanceOf',
        args: [props.account]
    })

    return {data, isError, isLoading, refetch}
}

export const usePeeperAllowance = (props: {owner: string, spender: string}) => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: PEEPER_TOKEN_ADDRESS,
        abi: PeeperTokenBuild.abi,
        functionName: 'allowance',
        args: [props.owner, props.spender]
    })

    return {data, isError, isLoading, refetch}
}

export const useApprove = (props: {spender: string, value: string}) => {
    const {config} = usePrepareContractWrite({
        address: PEEPER_TOKEN_ADDRESS,
        abi: PeeperTokenBuild.abi,
        functionName: 'approve',
        args: [props.spender, props.value]
    })

    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}
