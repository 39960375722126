import {
    Box,
    Flex,
    HStack,
    Image,
    IconButton,
    Spacer,
    Link,
    VStack,
    useDisclosure,
  } from "@chakra-ui/react";
  import { HamburgerIcon } from "@chakra-ui/icons";
  import { useEffect, useState } from "react";
  import Logo from "../../assets/icons/PeeperLogo.svg";
  import { Link as ReactLink } from "react-router-dom";

  
  export const NotLoggedNavBar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMobile, setIsMobile] = useState(false);
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const RenderItensMobile = ({ children }: { children: any }) => {
      if (isMobile) {
        return <VStack>{children}</VStack>;
      }
      return <HStack spacing={"10"}>{children}</HStack>;
    };
  
    return (
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        color="white"
      >
              <Link as={ReactLink} to="/">
        <Flex align="center">
          <Image src={Logo} />
        </Flex>
        </Link>
  
        {isMobile ? (
          <IconButton
            display={{ base: "block", md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            icon={<HamburgerIcon />}
            aria-label={""}
          />
        ) : (
          <Spacer />
        )}
  
        <Box
          display={{ base: isOpen ? "block" : "none", md: "flex" }}
          width={{ base: "full", md: "auto" }}
          alignItems="center"
          flexGrow={1}
        >
          <HStack
            w={"100%"}
            spacing={4}
            align="center"
            justify={{ base: "center", md: "flex-end" }}
          >
            <RenderItensMobile>
              <a href="https://t.me/peeper_mint_community" target="_blank">
                <Link
                  fontFamily={"gotham-rounded-bold"}
                  color={"whitesmoke"}
                  fontSize={"medium"}
                >
                  TELEGRAM
                </Link>
              </a>{" "}
              <a href="https://twitter.com/peeper_mint" target="_blank">
                <Link
                  fontFamily={"gotham-rounded-bold"}
                  color={"whitesmoke"}
                  fontSize={"medium"}
                >
                  TWITTER
                </Link>
              </a>
            </RenderItensMobile>
          </HStack>
        </Box>
      </Flex>
    );
  };
  