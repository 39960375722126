import { Button, Image, Modal, ModalBody, Text, Link, ModalContent, ModalHeader, ModalOverlay, useDisclosure, VStack, Heading, Spinner, Badge, HStack, useToast, Box } from '@chakra-ui/react'
import TGIcon from '../../assets/icons/telegram.png'
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { auth, firestore, service } from '../../firebase'
import { DbUser } from '../../types/user'

export const ConnectTelegram = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [userConnectToken, setUserConnectToken] = useState<string>()

    const getOrCreateToken = async () => {
        const userRef = doc(firestore, 'users', auth.currentUser?.uid ?? '')
        const user = await getDoc(userRef)
        if(!user.exists()) throw new Error('User not found')
        const data = user.data() as DbUser
        if(!data.telegramConnectToken) {
            const resp = await service.createTelegramConnectionToken()
            setUserConnectToken(resp.data as string)
        } else {
            setUserConnectToken(data.telegramConnectToken)
        }
    }

    const toast = useToast()

    useEffect(() => {
        getOrCreateToken()
    }, [])

    return (
        <>
            <Button w={'100%'} onClick={onOpen} rightIcon={<Image src={TGIcon} w={8} h={8}/>} colorScheme="blue">
                Connect Telegram
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'gray.700'} color={'white'}>
                    <ModalHeader>Connect your telegram</ModalHeader>
                    <ModalBody>
                    <VStack alignItems={'flex-start'}>
                        {
                            !userConnectToken ?
                            <Spinner size={'lg'} colorScheme='pink' />
                            :
                            (
                                <>
                                    <Heading size={'sm'}>                          1) Go to {' '}
                            <Link color={'blueviolet'} href='https://t.me/peeper_mint_bot' isExternal>
                                Peeper Bot on TG {' '}
                                <ExternalLinkIcon />
                            </Link>
                        </Heading>
                        <Heading size={'sm'}>
                            2) Click start
                        </Heading>

                        <HStack marginY={2} alignItems={'baseline'} justifyContent={"center"}>
                        <Heading size={'sm'} colorScheme='yellow'>
                            3) Type:
                        </Heading>
                <Box
                  padding={1}
                  borderRadius={10}
                  fontFamily={"gotham-rounded-bold"}
                  bg={"blueviolet"}
                >
                    <Text>
                  /connect {userConnectToken}
                  </Text>
                </Box>
                <Button
                  borderRadius={20}
                  colorScheme="purple"
                  onClick={() => {
                    navigator.clipboard.writeText(`/connect ${userConnectToken}`);
                    toast({
                      status: "info",
                      title: "Telegram token copied to clipboard",
                      duration: 3000,
                    });
                  }}
                  rightIcon={<CopyIcon />}
                  size={"sm"}
                >
                  Copy
                </Button>
              </HStack>
              <Heading size={'sm'}>
                            4) Send Message
                        </Heading>
                                </>
                            )
                        }

                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}